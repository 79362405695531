import React from "react";
import lock from "../../assets/images/lock.svg";

interface AccessDeniedModalProps {
  onClose: () => void; // The type for the onClose function
}

const AccessDeniedModal: React.FC<AccessDeniedModalProps> = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-[#5F6670] bg-opacity-50">
      <div className="bg-[#111827] rounded-lg shadow-lg p-8 max-w-2xl w-full min-h-[400px] flex flex-col justify-between text-center">
        <div>
          <img
            aria-hidden="true"
            alt="lock-icon"
            src={lock}
            className="mx-auto mb-6 w-20 h-20 md:w-24 md:h-24"
          />
          <h2 className="text-xl font-semibold text-[#D1D5DB] mb-2">
            Access Denied
          </h2>
          <p className="text-sm text-[#D1D5DB] mb-6">
            You don't have permission to access this page. Contact the admin to
            get access or return to the home page and browse other pages.
          </p>
        </div>
        <button
          type="button"
          className="bg-[#3485E2] text-white hover:bg-blue-700 p-2 rounded-lg mx-auto mb-6 w-full max-w-[350px]"
          onClick={onClose}
        >
          Go to Home page
        </button>
      </div>
    </div>
  );
}

export default AccessDeniedModal;
