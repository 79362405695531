import { Route, Routes } from "react-router-dom";
import AppSelection from "../pages/base/AppSelection";
import ForgotPasswordModal from "../pages/base/ForgotPasswordModal";
import Login from "../pages/base/Login";
import UserRegister from "../pages/base/UserRegister";
import OmsRoutes from "./OmsRoutes";
import TmsRoutes from "./TmsRoutes";
import UmsRoutes from "./UmsRoutes";

function BaseRoutes() {
  return (
    <Routes>
        <Route path="" element={<Login />} />
        <Route path="password-reset" element={<ForgotPasswordModal />} />
        <Route path="register" element={<UserRegister />}/>
        <Route path="welcome" element={<AppSelection />} />
        <Route path="/ums/*" element={<UmsRoutes />} />
        <Route path="/tms/*" element={<TmsRoutes />} />
        <Route path="/oms/*" element={<OmsRoutes />} />

    </Routes>
  );
}

export default BaseRoutes;
