import { setUserToken } from "../../redux/authSlice";
import { AppDispatch } from "../../redux/store";
import { OAuthTokenClientBase } from "../common/OAuthTokenClientBase";

export class UserTokenClient extends OAuthTokenClientBase {

    base_url = process.env.REACT_APP_API_URL || '';
    client_id = process.env.REACT_APP_CLIENT_ID || '';
    client_secret = process.env.REACT_APP_CLIENT_SECRET || '';

    async GetOAuth2Token(dispatch: AppDispatch, username: string, password: string, scope?: string): Promise<void> {
        await super.GetOAuth2Token(dispatch, username, password, scope);
        dispatch(setUserToken(this.token));
    }

}