import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import more from "../../assets/images/more-vertical.svg";
import x from "../../assets/images/x.svg";
import SmallSpinner from "../../components/common/SmallSpinner";
import { UserApiClient } from "../../services/user/UserApiClient";
import {
  UserDTO,
  UserInviteDTO,
  UserRole,
  UserRoleDTO,
} from "../../swagger-apis/generated-users-api";
import DetailsModal from "./DetailsModal";

interface UserDetails {
  user: UserDTO;
  roles: string[];
}

function ManageUsers() {
  const token = useSelector((state: any) => state.auth.user_token);
  const userName = useSelector((state: any) => state.auth.username);
  const [userData, setUserData] = useState<UserDetails[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserDetails[]>([]);
  const [userEmail, setUserEmail] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<UserRole | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [selectedRow, setSelectedRow] = useState<UserDTO>();
  const [isComponentVisible, setComponentVisible] = useState(false);
  const [selectedValues, setSelectedValues] = useState<UserRole[]>([]);
  const [email, setEmail] = useState("");
  const tenant = useSelector((state: any) => state.auth.tenant_id);

  const toggleOptions = (index: number) => {
    setSelectedUser(selectedUser === index ? null : index);
  };

  const handleOptionClick = async (action: string, user: UserDetails) => {
    setLoading(true);
    var apiClient = new UserApiClient().userApiService();
    if (action === "Suspend User") {
      var userInvite: UserInviteDTO = {
        email: user.user.email,
        organization_name: user.user.organization_name,
      };
      await apiClient
        .cancelUserInviteApiV1UserInviteDelete(userInvite)
        .then((response: any) => fetchUsers())
        .then(() =>
          toast.success("Successfully suspended User: " + user.user.email)
        )
        .catch((error) => {
          toast.error("Failed to suspend user");
        });
    } else if (action === "Terminate User") {
      var userInvite: UserInviteDTO = {
        email: user.user.email,
        organization_name: user.user.organization_name,
      };
      await apiClient
        .cancelUserInviteApiV1UserInviteDelete(userInvite)
        .then((response: any) => fetchUsers())
        .then(() =>
          toast.success("Successfully terminated User: " + user.user.email)
        )
        .catch((error) => {
          toast.error("Failed to suspend user");
        });
    } else if (action === "Cancel Invite") {
      var userInvite: UserInviteDTO = {
        email: user.user.email,
        organization_name: user.user.organization_name,
      };

      await apiClient
        .cancelUserInviteApiV1UserInviteDelete(userInvite)
        .then((response: any) => fetchUsers())
        .then(() => {
          toast.success(
            "Successfully cancelled invite for User: " + user.user.email
          );
        })
        .catch((error) => {
          toast.error("Failed to cancel the invite for user");
        });
    } else {
      toast.success("Successfully resend invite for User: " + user.user.email);
    }
    setSelectedUser(null);
    setLoading(false);
  };

  const handleRowClick = (rowData: any) => {
    setSelectedRow(rowData);
    setComponentVisible(true); // Show the new component
  };

  // const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const value = event.target.value;
  //   const roleEnumKey = (
  //     Object.keys(UserRole) as Array<keyof typeof UserRole>
  //   ).find((key) => UserRole[key] === value);
  //   if (roleEnumKey) {
  //     setSelectedRole(UserRole[roleEnumKey]);
  //     console.log("Selected Value:", UserRole[roleEnumKey]);
  //   }
  // };

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const roleEnumKey = (
      Object.keys(UserRole) as Array<keyof typeof UserRole>
    ).find((key) => UserRole[key] === value);

    if (roleEnumKey) {
      const roleEnumValue = UserRole[roleEnumKey];

      console.log("Role: ", roleEnumValue);
      if (!selectedValues.includes(roleEnumValue)) {
        setSelectedValues([...selectedValues, roleEnumValue]);
      }
      setSelectedRole(null); // Reset dropdown after selection
    }
  };

  const handleRemove = (valueToRemove: any) => {
    setSelectedValues(selectedValues.filter((val) => val !== valueToRemove));
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      var apiClient = new UserApiClient().userApiService();
      var roleApiClient = new UserApiClient().userRoleApiService();
      var users: UserDetails[] = [];
      await apiClient
        .getUsersApiV1UsersGet()
        .then(async (response: any) => {
          console.log(response);
          console.log(response.data.users);
          if (response.data.users) {
            for (const user of response.data.users) {
              await roleApiClient
                .getUsersApiV1UserRolesUserNameGet(user.user_name)
                .then((response: any) => {
                  var userDetail: UserDetails = {
                    user: user,
                    roles: response.data.user_roles
                      ? response.data.user_roles
                      : [],
                  };
                  users.push(userDetail);
                })
                .catch(() => {
                  console.log("Error getting roles for user", user.user_name);
                  var userDetail: UserDetails = {
                    user: user,
                    roles: [],
                  };
                  users.push(userDetail);
                });
            }
            // response.data.users.forEach((user: any) => {

            //   var userDetail: UserDetails = {
            //     email: user.email,
            //     username: user.user_name,
            //     roles: ["ORG_ADMIN", "SUPER_USER"],
            //   };
            //   users.push(userDetail);
            // });

            setUserData(users);
            setFilteredUsers(users);
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching users data:", error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const RedirectLogin = () => {
    navigate("/admin");
  };

  useEffect(() => {
    if (token) {
      fetchUsers();
    }

    if (!token) RedirectLogin();
  }, [token]);

  useEffect(() => {
    const lowercasedFilter = searchQuery.toLowerCase();
    const filteredData = userData.filter(
      (user) =>
        user.user.user_name.toLowerCase().includes(lowercasedFilter) ||
        user.user.email.toLowerCase().includes(lowercasedFilter)
    );
    setFilteredUsers(filteredData);
  }, [searchQuery, userData]);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isButtonDisabled = !isValidEmail(email) || selectedValues.length === 0;

  const handleRoleAttachment = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    var userRoleApiClient = new UserApiClient().userRoleApiService();
    var userApiClient = new UserApiClient().userApiService();
    var roles: string[] = [];
    var successFulRoles: string[] = [];
    var failedRoles: string[] = [];
    //var skippedRoles: string[] = [];
    console.log("Selected Roles", selectedValues);

    var userInvite: UserInviteDTO = {
      email: email,
      organization_name: tenant,
    };
    await userApiClient
      .inviteUserApiV1UserInvitePost(userInvite)
      .then(async (response) => {
        for (var i = 0; i < selectedValues.length; i++) {
          var r = Object.values(UserRole).find(
            (role) => role == selectedValues[i]
          );
          console.log("r: ", selectedValues[0]);
          if (r) {
            var userRoleDto: UserRoleDTO = {
              user_name: email,
              role: r,
            };
            await userRoleApiClient
              .attachUserRoleApiV1UserRolesPost(userRoleDto)
              .then((response) => {
                successFulRoles.push(selectedValues[i] + " ");
              })
              .catch((error) => {
                failedRoles.push(selectedValues[i] + " ");
              });
          }
        }
      })
      .catch((error) => {
        toast.error("Error attaching roles");
      });

    if (successFulRoles.length > 0) {
      setEmail("");
      await fetchUsers();
      toast.success(
        "Successfully attached Roles: " + successFulRoles.join(",")
      );
    }
    if (failedRoles.length > 0) {
      toast.error("Failed to attach Roles: " + failedRoles.join(","));
    }

    setLoading(false);
  };

  useEffect(() => {
    if (selectAll) {
      setSelectedUsers(filteredUsers.map((_, i) => i));
    } else {
      setSelectedUsers([]);
    }
  }, [selectAll, filteredUsers]);

  return (
    <div className="bg-[#111827] p-6 text-[#B9B7BF] min-h-screen overflow-hidden relative">
      {loading && (
        <div className="fixed inset-0 bg-[#111827] bg-opacity-75 flex justify-center items-center z-10">
          <SmallSpinner />
        </div>
      )}
      <h1 className="text-xl sm:text-2xl font-bold text-[#B9B7BF] mb-6 sm:mb-10">
        Invite Users
      </h1>
      <div className="flex flex-col sm:flex-row mb-5">
        <div className="w-full max-w-6xl space-y-4">
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            {/* Input */}
            <input
              type="text"
              id="users-emails"
              className="mt-1 block w-full sm:w-1/2 p-2 border border-[#374151] rounded-md bg-[#111827] text-[#B9B7BF]"
              placeholder="Search by Email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />

            {/* Select Dropdown */}
            <select
              id="languageSelect"
              className="bg-[#111827] text-[#B9B7BF] border border-[#374151] rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-300 w-full sm:w-1/3"
              value={selectedRole ?? ""}
              onChange={handleRoleChange}
            >
              <option value="">Select Role</option>
              <option value={UserRole.OrgAdmin}>{UserRole.OrgAdmin}</option>
              <option value={UserRole.Dispatcher}>{UserRole.Dispatcher}</option>
              <option value={UserRole.Driver}>{UserRole.Driver}</option>
            </select>

            {/* Invite Button */}
            <button
              className={`bg-blue-500 text-white border border-blue-700 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-300 w-full sm:w-1/6 ${
                isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleRoleAttachment}
              disabled={isButtonDisabled}
              title={
                isButtonDisabled
                  ? "Please enter a valid email or select at least one role."
                  : ""
              }
            >
              Invite
            </button>
          </div>

          {/* Chips */}
          <div className="flex flex-wrap gap-2">
            {selectedValues.map((value, index) => (
              <div
                key={index}
                className="flex items-center bg-[#1f2937] text-[#B9B7BF] border border-[#374151] px-3 py-1 rounded"
              >
                <button
                  className="text-white p-1 mr-2"
                  onClick={() => handleRemove(value)}
                >
                  X
                </button>
                <span>{value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-10 sm:mt-20 mb-6 sm:mb-10">
        <h1 className="text-xl sm:text-2xl font-bold text-[#B9B7BF]">
          Manage Users
        </h1>
      </div>

      <div className="relative w-full sm:w-1/2 mb-6 sm:mb-10">
        <input
          type="text"
          id="search-users"
          className="mt-1 block w-full p-2 border border-[#374151] rounded-md bg-[#111827] text-[#B9B7BF] pr-10"
          placeholder="Search by username, email"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <FaSearch className="h-5 w-5 text-[#B9B7BF]" />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-[#1F2937] border border-[#374151]">
          <thead>
            <tr className="bg-[#374151] text-[#B9B7BF]">
              <th className="p-4 text-left">Email Address</th>
              <th className="p-4 text-left">Username</th>
              <th className="p-4 text-left">Role</th>
              <th className="p-4 text-left">Status</th>
              <th className="p-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr
                key={index}
                className="border-b border-[#374151]"
              >
                <td
                  className="p-4"
                  onClick={() => handleRowClick(user.user)}
                >
                  {user.user.email}
                </td>
                <td className="p-4">{user.user.user_name}</td>
                <td className="p-4">
                  {user.roles.length > 0 && (
                    <select className="bg-[#1F2937] text-[#B9B7BF] border border-[#374151] rounded p-1">
                      {user.roles.map((role, index) => (
                        <option key={index}>{role}</option>
                      ))}
                    </select>
                  )}
                </td>
                <td className="p-4">{user.user.status}</td>
                <td className="p-4 relative">
                  <button
                    className="ml-2"
                    onClick={() => toggleOptions(index)}
                  >
                    <img
                      src={more}
                      className="h-10 w-7 py-1 mx-2 my-1"
                      alt="More actions"
                      aria-hidden="true"
                    />
                  </button>
                </td>
                {selectedUser === index && (
                  <div className="absolute right-0 mt-2 w-48 bg-[#374151] border border-[#374151] rounded-md shadow-lg z-20">
                    <div className="flex justify-end m-2">
                      <img
                        src={x}
                        alt="close"
                        className="w-5 h-5 cursor-pointer"
                        onClick={() => setSelectedUser(null)}
                      />
                    </div>
                    <ul>
                      <li>
                        <div
                          className="block px-4 py-2 text-sm text-[#B9B7BF] hover:bg-[#1F2937] cursor-pointer"
                          onClick={() =>
                            handleOptionClick("Suspend User", user)
                          }
                        >
                          Suspend User
                        </div>
                      </li>
                      <li>
                        <div
                          className="block px-4 py-2 text-sm text-[#B9B7BF] hover:bg-[#1F2937] cursor-pointer"
                          onClick={() =>
                            handleOptionClick("Terminate User", user)
                          }
                        >
                          Terminate User
                        </div>
                      </li>
                      <li>
                        <div
                          className="block px-4 py-2 text-sm text-[#B9B7BF] hover:bg-[#1F2937] cursor-pointer"
                          onClick={() =>
                            handleOptionClick("Cancel Invite", user)
                          }
                        >
                          Cancel Invite
                        </div>
                      </li>
                      <li>
                        <div
                          className="block px-4 py-2 text-sm text-[#B9B7BF] hover:bg-[#1F2937] cursor-pointer"
                          onClick={() =>
                            handleOptionClick("Resend Invite", user)
                          }
                        >
                          Resend Invite
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isComponentVisible && selectedRow && (
        <DetailsModal
          onClose={() => setComponentVisible(false)}
          data={selectedRow}
        />
      )}
    </div>
  );
}

export default ManageUsers;
