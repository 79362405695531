import { useDispatch, useSelector } from "react-redux";
import account from "../../assets/images/Ellipse 2.svg";
import logout from "../../assets/images/log-out.svg";
import { useState } from "react";
import { clearTokens } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";

export default function UserOptionsDropdown({
  logoutRedirectionPath,
  classNames,
}: {
  logoutRedirectionPath: string;
  classNames?: string;
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userName = useSelector((state: any) => state.auth.username);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    dispatch(clearTokens());
    navigate(logoutRedirectionPath);
    console.log("Logout clicked");
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className={`relative ${classNames}`}>
      <div
        onClick={toggleDropdown}
        className="inline-flex flex-nowrap cursor-pointer items-center border border-[#5D5E68] rounded-full p-1.5 pe-3"
      >
        <img
          src={account}
          alt="Account Icon"
          className="h-6 w-6 me-1.5 cursor-pointer"
        />
        <span className="whitespace-nowrap text-sm font-medium text-[#5D5E68]">
          {userName}
        </span>
      </div>
      {/* Dropdown */}
      {isDropdownOpen && (
        <div className="absolute left-2 mt-2 min-w-[110px] bg-gray-400 text-white rounded-md shadow-lg z-20">
          <button
            onClick={handleLogout}
            className="w-full text-left px-4 py-2 hover:bg-gray-700 hover:rounded-md flex items-center"
          >
            <img
              src={logout}
              alt="Logout Icon"
              className="h-5 w-5 mr-2"
            />
            <span className="text-white">Logout</span>
          </button>
        </div>
      )}
    </div>
  );
}
