import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import img from "../../assets/images/logistics.svg";
import Logo from "../../components/common/Logo";
import Spinner from "../../components/common/Spinner";
import { setTenantId, setUserName } from "../../redux/authSlice";
import { validatePassword } from "../../services/common/Helper";
import { UserApiClient } from "../../services/user/UserApiClient";
import { UserTokenClient } from "../../services/user/UserTokenClient";
import { UserPasswordDTO } from "../../swagger-apis/generated-users-api/api";

const ForgotPasswordModal: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const username = queryParams.get("username");
  const [tenant, setTenant] = useState<string>("");
  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (!currentPassword || !newPassword || !confirmPassword) {
      toast.error("All fields are required.");
      setLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match.");
      setLoading(false);
      return;
    }

    if (newPassword === currentPassword) {
      toast.error("New password cannot be the same as the current password.");
      setLoading(false);
      return;
    }

    if (!validatePassword(newPassword)) {
      toast.error(
        "Password must be at least 5 characters long, include one uppercase letter, and one special character."
      );
      setLoading(false);
      return;
    }

    const apiClient = new UserApiClient().userApiService();
    var userApiClient = new UserTokenClient();

    const password: UserPasswordDTO = {
      current_password: currentPassword,
      new_password: newPassword,
    };

    if (username && tenant) {
      dispatch(setTenantId(tenant));
      dispatch(setUserName(username));
      console.log(tenant);
      try {
        await apiClient.resetPasswordApiV1UsersUserNamePasswordResetPatch(
          username,
          tenant,
          password
        ).then(async (response) => {
          console.log(response);
          toast.success("Password reset successfully.");
          await userApiClient.GetOAuth2Token(dispatch, username, password.new_password, "tenant:"+tenant);

          setLoading(false);
          handleNavigate();          })
          .catch((error) => {
            toast.error(
              "Failed to reset password. Please check your current password."
            );
          });
      } catch (error) {
        console.error("Password reset error:", error);
        toast.error(
          "Failed to reset password. Please check your current password."
        );
      }
    } else {
      toast.error("Username not found.");
    }

    setLoading(false);
  };

  const handleNavigate = () => {
    navigate("/register");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#111827]">
      {loading && <Spinner message="Processing..." />}
      <div className="bg-[#1F2937] p-6 md:p-12 rounded-lg shadow-lg text-center w-full max-w-md md:max-w-lg">
        <div className="mb-8">
          <Logo
            className="w-20 h-20 md:w-24 md:h-24 mx-auto"
            imagePath={img}
          />
        </div>
        <h1 className="text-xl md:text-2xl font-bold text-[#B9B7BF] mb-4">
          Reset Password
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-[#B9B7BF] mb-2">
              Current Password
            </label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="w-full p-2 rounded-lg bg-[#111827] text-[#B9B7BF] border border-[#374151]"
            />
          </div>
          <div className="mb-4">
            <label className="block text-[#B9B7BF] mb-2">New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-2 rounded-lg bg-[#111827] text-[#B9B7BF] border border-[#374151]"
            />
          </div>
          <div className="mb-8">
            <label className="block text-[#B9B7BF] mb-2">
              Confirm New Password
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full p-2 rounded-lg bg-[#111827] text-[#B9B7BF] border border-[#374151]"
            />
          </div>
          <div className="mb-8">
            <label className="block text-[#B9B7BF] mb-2">Tenant</label>
            <input
              type="text"
              value={tenant}
              onChange={(e) => setTenant(e.target.value)}
              className="w-full p-2 rounded-lg bg-[#111827] text-[#B9B7BF] border border-[#374151]"
            />
          </div>
          <button
            type="submit"
            className="bg-[#3485E2] text-white hover:bg-blue-700 p-2 rounded-lg w-full max-w-[350px] mx-auto"
            disabled={loading}
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
