import { useState } from "react";
import { Link } from "react-router-dom";
import menu from "../../assets/images/menu.svg";
import cross from "../../assets/images/x.svg";
import UserOptionsDropdown from "./UserOptionsDropdown";

type LinkType = {
  name: string;
  path: string;
};

type NavigationSection = {
  title: string;
  links: LinkType[];
};

interface AdminSidebarProps {
  navigationSections: NavigationSection[];
  isSidebarOpen?: boolean;
  logoutRedirectionPath: string;
  className?: string; // Optional className prop
}

const AdminSidebar: React.FC<AdminSidebarProps> = ({
  navigationSections,
  isSidebarOpen = false,
  logoutRedirectionPath,
  className = "", // Default to an empty string if no className is passed
}) => {
  const [isOpen, setIsOpen] = useState(isSidebarOpen);

  return (
    <div className={`relative ${className}`}>
      {/* Header Section with Toggle Button */}
      <div
        className={`fixed top-0 left-0 p-4 bg-[#1F2937] text-white z-50 md:hidden ${
          isOpen ? "hidden" : "block"
        }`}
      >
        <button
          className="focus:outline-none block align-middle"
          onClick={() => setIsOpen(!isOpen)}
        >
          <img
            src={menu}
            alt="Toggle sidebar"
            className="w-6 h-6"
          />
        </button>
      </div>

      {/* Sidebar - Visible on medium and larger screens, toggled on mobile */}
      <aside
        className={`fixed inset-0 bg-[#1F2937] text-[#B9B7BF] p-4 flex flex-col justify-between gap-5 pb-16 md:overflow-y-auto md:relative md:w-64 md:h-full ${
          isOpen ? "flex" : "hidden md:flex"
        } z-40`}
        style={{ top: 0, left: 0 }}
      >
        <nav>
          {navigationSections.map((section) => (
            <div
              key={section.title}
              className="mt-4"
            >
              <h2 className="text-sm font-semibold text-gray-400 mb-4">
                {section.title}
              </h2>
              <ul className="mt-2">
                {section.links.map((link) => (
                  <li key={link.name}>
                    <Link
                      to={link.path}
                      onClick={() => setIsOpen(false)}
                      className="block py-2 px-3 mt-6 rounded-md hover:bg-[#374151] hover:text-white transition-colors duration-300"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </nav>
        <UserOptionsDropdown
          logoutRedirectionPath={logoutRedirectionPath}
          classNames="lg:hidden"
        />
      </aside>

      {/* Close button for mobile view */}
      {isOpen && (
        <button
          className="fixed top-3 right-0 p-4 text-white z-50 md:hidden"
          onClick={() => setIsOpen(false)}
        >
          <img
            src={cross}
            alt="Close sidebar"
            className="w-6 h-6"
          />
        </button>
      )}
    </div>
  );
};

export default AdminSidebar;
