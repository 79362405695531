import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import AdminSidebar from "../../components/common/AdminSidebar";
import Header from "../../components/common/Header";
import Manage from "./Manage";
import Onboard from "./Onboard";
import DashboardOverview from "./Overview";

// Define the Links type
type LinkType = {
  name: string;
  path: string;
};

const Dashboard = () => {
  const token = useSelector((state: any) => state.auth.user_token);
  const [links, setLinks] = useState<LinkType[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage sidebar visibility

  const navigate = useNavigate();
  const RedirectLogin = () => {
    navigate("/admin");
  };

  useEffect(() => {
    if (token) {
      const linksArray: LinkType[] = [
        { name: "Dashboard", path: "" },
        { name: "Onboard Organization", path: "onboard" },
        { name: "Manage Organization", path: "manage" },
      ];
      setLinks(linksArray);
    }

    if (!token) {
      RedirectLogin();
    }
  }, [token]);

  const navigationSections = [
    {
      title: "ORGANIZATION MANAGEMENT",
      links: links.length
        ? links
        : [
            { name: "Dashboard", path: "" },
            { name: "Onboard Organization", path: "onboard" },
            { name: "Manage Organization", path: "manage" },
          ],
    },
  ];

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <Header logoutRedirectionPath="/admin" />
      <div className="flex flex-1 overflow-hidden">
        <AdminSidebar
          navigationSections={navigationSections}
          logoutRedirectionPath="/admin"
        />
        {/* Main Content */}
        <main className="flex-1 bg-gray-100 overflow-auto custom-scrollbar">
          <Routes>
            <Route
              path=""
              element={<DashboardOverview />}
            />
            <Route
              path="onboard"
              element={<Onboard />}
            />
            <Route
              path="manage"
              element={<Manage />}
            />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
