import { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import img from "../../assets/images/logistics.svg";
import Logo from "../../components/common/Logo";
import SmallSpinner from "../../components/common/SmallSpinner";
import Spinner from "../../components/common/Spinner";
import { UserApiClient } from "../../services/user/UserApiClient";
import { Status, UserPatchDTO } from "../../swagger-apis/generated-users-api";

function UserRegister() {

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state: any) => state.auth.user_token);
  const userName = useSelector((state: any) => state.auth.username);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState<boolean>(false);


  const clearData = () => {
    setFirstName('');
    setLastName('');
    setAddress('');
    setPhoneNumber('');
  }
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate("/");
  };

  const updateUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      var apiClient = new UserApiClient().userApiService();
      
      var user: UserPatchDTO = {
        user_name: userName,
        first_name: firstName,
        last_name: lastName,
        address: address,
        status: Status.Active
      };
      await apiClient.updateUserApiV1UsersEmailPatch(userName, user)
        .then((response: any) => {
          console.log(response)
          toast.success('Successfully added User details for Organization')
          
          clearData();
          handleContinue();
        })
        .catch((error: any) => {
          console.log('User error', error);
          toast.error("Failed to attach user.");
        });
    } catch (error) {
      console.error("Error fetching users data:", error);
      toast.error("Failed to attach user.");

    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#111827]">
      {isLoading && (
        <div className="fixed inset-0 bg-[#111827] bg-opacity-75 flex justify-center items-center z-10">
          <SmallSpinner />
        </div>
      )}
      <div className="mt-2"></div>
      <div className="bg-[#1F2937] p-8 sm:p-6 md:p-8 rounded-lg shadow-lg w-full sm:w-[90%] md:w-[75%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]">
        <div className="flex flex-col items-center mb-8">
          <Logo className="w-48 h-48 mx-auto" imagePath={img} />
          <h2 className="text-2xl font-bold text-blue-300 mt-2 text-center">
            Fill In Your Details
          </h2>
        </div>
        <form className="space-y-4">
          <div>
            <label
              className="block text-sm text-[#9ca3af]"
              htmlFor="first-name"
            >
              First Name
            </label>
            <input
              type="text"
              id="first-name"
              className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm text-[#9ca3af]" htmlFor="last-name">
              Last Name
            </label>
            <input
              type="text"
              id="last-name"
              className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm text-[#9ca3af]" htmlFor="address">
              Address
            </label>
            <input
              type="text"
              id="address"
              className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block text-sm text-[#9ca3af]"
              htmlFor="phone-number"
            >
              Phone Number
            </label>
            <input
              type="tel"
              id="phone-number"
              className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="flex justify-center mb-4">
            {isLoading ? (
              <Spinner message="Saving User Details" />
            ) : (
              <button
                type="submit"
                className="bg-[#3485E2] text-white hover:bg-blue-700 rounded-md p-2 w-full sm:w-[60%] lg:w-[50%] xl:w-[40%]"
                disabled={
                  !firstName.trim() ||
                  !lastName.trim() ||
                  !address.trim() ||
                  !phoneNumber.trim()
                }
                onClick={updateUser}
              >
                Create
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserRegister;
