import Cookies from "js-cookie";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminSidebar from "../../components/common/AdminSidebar";
import Header from "../../components/common/Header";
import AccessDeniedModal from "./AccessDeniedModal";

const AppSelection: React.FC = () => {
  const [selected, setSelected] = useState<string>("");
  const [message, setMessage] = useState<string>("Set As Default");
  const navigate = useNavigate();
  const userName = useSelector((state: any) => state.auth.username);
  const [accessDenied, setAccessDenied] = useState<boolean>(false);

  const navigationSections = [
    {
      title: "USER MANAGEMENT",
      links: [],
    },
    {
      title: "OMS",
      links: [],
    },
    {
      title: "TMS",
      links: [],
    },
  ];

  const handleSelect = () => {
    if (selected) {
      const cookieName = `user_selection_${userName}`;
      const value = selected;
      Cookies.set(cookieName, value, { expires: 7 });
      if (value === "user-management") {
        navigate("/ums/dashboard");
      } else if (value === "order-management") {
        setAccessDenied(true);
      } else if (value === "transportation-management") {
        setAccessDenied(true);
      }
    } else {
      toast.error("Please select a default value");
    }
  };

  const handleCloseModal = () => {
    setAccessDenied(false);
  };

  return (
    <div className="flex flex-col h-screen bg-[#111827] text-[#B9B7BF] relative">
      <Header logoutRedirectionPath="/" />

      {accessDenied && (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50">
          <AccessDeniedModal onClose={handleCloseModal} />
        </div>
      )}

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <AdminSidebar
          navigationSections={navigationSections}
          isSidebarOpen={false}
          logoutRedirectionPath="/"
        />

        <div className="flex flex-1 flex-col p-6 overflow-auto">
          <div className="flex flex-col md:flex-col lg:flex-row gap-12 justify-center items-center">
            {/* User Management Box */}
            <div
              onClick={() => handleSelect()}
              className={`relative p-6 rounded-lg w-full md:w-full lg:w-80 h-60 md:h-72 flex flex-col items-center justify-center cursor-pointer bg-[#1F2937] border-2 ${
                selected === "user-management"
                  ? "border-[#B9B7BF]"
                  : "border-transparent"
              }`}
            >
              <span className="text-white text-xl mb-4">User Management</span>
              <div className="absolute bottom-4 right-4 flex items-center">
                <span className="mr-2">
                  {selected === "user-management" ? "Default" : message}
                </span>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelected("user-management");
                  }}
                  className={`relative w-6 h-6 rounded-full border-2 ${
                    selected === "user-management"
                      ? "border-green-500"
                      : "border-[#B9B7BF]"
                  } bg-transparent flex items-center justify-center`}
                >
                  {selected === "user-management" && (
                    <svg
                      className="w-4 h-4 text-green-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>

            {/* Order Management System Box */}
            <div
              onClick={() => handleSelect()}
              className={`relative p-6 rounded-lg w-full md:w-full lg:w-80 h-60 md:h-72 flex flex-col items-center justify-center cursor-pointer bg-[#1F2937] border-2 ${
                selected === "order-management"
                  ? "border-[#B9B7BF]"
                  : "border-transparent"
              }`}
            >
              <span className="text-white text-xl mb-4">Order Management</span>
              <div className="absolute bottom-4 right-4 flex items-center">
                <span className="mr-2">
                  {selected === "order-management" ? "Default" : message}
                </span>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelected("order-management");
                  }}
                  className={`relative w-6 h-6 rounded-full border-2 ${
                    selected === "order-management"
                      ? "border-green-500"
                      : "border-[#B9B7BF]"
                  } bg-transparent flex items-center justify-center`}
                >
                  {selected === "order-management" && (
                    <svg
                      className="w-4 h-4 text-green-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>

            {/* Transportation Management System Box */}
            <div
              onClick={() => handleSelect()}
              className={`relative p-6 rounded-lg w-full md:w-full lg:w-80 h-60 md:h-72 flex flex-col items-center justify-center cursor-pointer bg-[#1F2937] border-2 ${
                selected === "transportation-management"
                  ? "border-[#B9B7BF]"
                  : "border-transparent"
              }`}
            >
              <span className="text-white text-xl mb-4">
                Transportation Management
              </span>
              <div className="absolute bottom-4 right-4 flex items-center">
                <span className="mr-2">
                  {selected === "transportation-management"
                    ? "Default"
                    : message}
                </span>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelected("transportation-management");
                  }}
                  className={`relative w-6 h-6 rounded-full border-2 ${
                    selected === "transportation-management"
                      ? "border-green-500"
                      : "border-[#B9B7BF]"
                  } bg-transparent flex items-center justify-center`}
                >
                  {selected === "transportation-management" && (
                    <svg
                      className="w-4 h-4 text-green-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppSelection;
