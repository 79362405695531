import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import more from "../../assets/images/more-vertical.svg";
import x from "../../assets/images/x.svg";
import SmallSpinner from "../../components/common/SmallSpinner";
import { OrganizationApiClient } from "../../services/organization/OrganizationApiClient";
import { OrganizationDTO, Status } from "../../swagger-apis/generated-organization-api";

interface UserDetails {
  email: string;
  username: string;
  roles: string[];
}

function Manage() {
  const token = useSelector((state: any) => state.auth.user_token);
  const userName = useSelector((state: any) => state.auth.username);
  const [organizationData, setOrganizationData] = useState<OrganizationDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [reload, setReload] = useState(false);

  const toggleOptions = (index: number) => {
    setSelectedUser(selectedUser === index ? null : index);
  };
  const handleOptionClick = async (action: string, org: OrganizationDTO) => {
    if (action === "Terminate Org") {
      var apiClient = new OrganizationApiClient().organizationApiService();
      var tenantId = org.site_url_prefix.replace('-', '_').replace('.', '_').toLowerCase();
      await apiClient.disableOrganizationApiV1OrganizationsTenantIdDelete(tenantId).then((response: any) => {
        toast.success("Successfully terminated Organization");
        setReload((prev) => !prev);
      }).catch((error) => {
        toast.error("Termination of Organization Failed");
      })

    }
    setSelectedUser(null);
  };

  const fetchOrganizations = async () => {
    setLoading(true);
    try {
      var apiClient = new OrganizationApiClient().organizationApiService();
      await apiClient
        .getOrganizationsApiV1OrganizationsGet()
        .then(async (response: any) => {
          console.log(response);
          console.log(response.data.organizations);
          setOrganizationData(response.data.organizations)
        })
        .catch((error: any) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching users data:", error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const RedirectLogin = () => {
    navigate("/admin");
  };

  useEffect(() => {
    if (token) {
      fetchOrganizations();
    }

    if (!token)
      RedirectLogin();
  }, [token, reload]);


  return (
    <div className="bg-[#111827] p-4 md:p-6 text-[#B9B7BF] min-h-screen overflow-hidden relative">
      {loading && (
        <div className="fixed inset-0 bg-[#111827] bg-opacity-75 flex justify-center items-center z-10">
          <SmallSpinner />
        </div>
      )}

      <div className="mt-10 mb-6 md:mt-20 md:mb-10">
        <h1 className="text-xl md:text-2xl font-bold text-[#B9B7BF]">
          Manage Organizations
        </h1>
      </div>

      {/* Table Wrapper for Responsiveness */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-[#1F2937] border border-[#374151] text-sm md:text-base">
          <thead>
            <tr className="bg-[#374151] text-[#B9B7BF]">
              <th className="p-3 md:p-4 text-left">Organization Name</th>
              <th className="p-3 md:p-4 text-left">Admin Email</th>
              <th className="p-3 md:p-4 text-left">Admin Status</th>
              <th className="p-3 md:p-4 text-left">Actions</th>
            </tr>
          </thead>

          <tbody>
            {organizationData.map((org, index) => (
              <tr key={index} className="border-b border-[#374151]">
                <td className="p-3 md:p-4">{org.organization_name}</td>
                <td className="p-3 md:p-4">{org.admin_email}</td>
                <td className="p-3 md:p-4">{org.admin_status}</td>
                <td className="p-3 md:p-4 relative">
                  <button
                    className={`ml-2 ${
                      org.admin_status === Status.Disabled
                        ? "cursor-not-allowed"
                        : ""
                    }`}
                    onClick={() => toggleOptions(index)}
                    disabled={org.admin_status === Status.Disabled}
                  >
                    <img
                      src={more}
                      className="h-6 w-6 md:h-10 md:w-7 py-1 mx-2 my-1"
                      alt="More actions"
                      aria-hidden="true"
                    />
                  </button>

                  {selectedUser === index && (
                    <div className="absolute right-0 mt-2 w-40 md:w-48 bg-[#374151] border border-[#374151] rounded-md shadow-lg z-20">
                      <div className="flex justify-end m-2">
                        <img
                          src={x}
                          alt="close"
                          className="w-4 h-4 md:w-5 md:h-5 cursor-pointer"
                          onClick={() => setSelectedUser(null)}
                        />
                      </div>
                      <ul>
                        <li>
                          <div
                            className="block px-4 py-2 text-sm text-[#B9B7BF] hover:bg-[#1F2937] cursor-pointer"
                            onClick={() =>
                              handleOptionClick("Terminate Org", org)
                            }
                          >
                            Terminate Organization
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Manage;
