import { Toaster } from "react-hot-toast";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./assets/styles/styles.css";
import AdminRoutes from "./routes/AdminRoutes";
import BaseRoutes from "./routes/BaseRoutes";

export default function App() {
  return (
    <Router>
      <Toaster position="top-center" reverseOrder={false} />

      <Routes>
        <Route path="/*" element={<BaseRoutes />} />
        <Route path="/admin/*" element={<AdminRoutes />} />
        
      </Routes>
    </Router>
  );
}
