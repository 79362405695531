import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import group from "../../assets/images/group.svg";
import SmallSpinner from "../../components/common/SmallSpinner"; // Import SmallSpinner
import { UserApiClient } from "../../services/user/UserApiClient";
import { UserCountResponseDTO } from "../../swagger-apis/generated-users-api";

interface Activity {
  icon: string;
  text: string;
  subtext: string;
}

interface StatsInfo {
  title: string;
  value: number;
}

interface Stats {
  total_count: StatsInfo;
  active_users: StatsInfo;
  pending_users: StatsInfo;
}

const DashboardOverview = () => {
  const token = useSelector((state: any) => state.auth.user_token);
  const userName = useSelector((state: any) => state.auth.username);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [stats, setStats] = useState<StatsInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchUserCountStats = async () => {
    setLoading(true); 
    try {
      let stats: StatsInfo[] = [];
      const apiClient = new UserApiClient().userApiService();
      await apiClient
        .getUsersCountApiV1UserCountGet()
        .then((response: AxiosResponse<UserCountResponseDTO>) => {
          //console.log("test",response.data.length);
          if (response.data && response.data.data && response.data.data.length > 0) {
            let total = 0;
            const data = response.data.data;
            let activeCount: number | undefined = 0;
            let pendingCount: number| undefined = 0;
            
            for (let i = 0; i < data.length; i++) {
              
              if (data[i].status === "ACTIVE") {
                activeCount = data[i].count;
              }

              if (data[i].status === "INVITED") {
                pendingCount = data[i].count;
              }

              total += data[i].count ?? 0;
            }
            stats.push({ title: "Total Users", value: total });
            stats.push({title: "Active Users",value: activeCount ?? 0});
            stats.push({title: "Pending Invitations",value: pendingCount ?? 0});
            setStats(stats);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching users data:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const fetchUserAudits = async () => {
    setLoading(true); // Start loading
    try {
      var apiClient = new UserApiClient().userAuditApiService();
      await apiClient
        .getAuditsApiV1AuditsUserNameGet(userName)
        .then((response) => {
          console.log(response);
          const activities: Activity[] = response.data.map(
            (item: any): Activity => ({
              icon: group,
              text: item.message,
              subtext: item.time,
            })
          );

          setActivities(activities);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching users data:", error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const RedirectLogin = () => {
    navigate("/");
  };

  useEffect(() => {
    console.log("Dashboard");
    console.log(token);
    console.log(userName);

    if (token) {
      //fetchUserAudits();
      fetchUserCountStats();
    }

    if(!token){
      RedirectLogin();
    }
  }, [token]);

  return (
    <div className="bg-[#111827] p-6 text-[#B9B7BF] min-h-screen overflow-hidden relative">
      {loading && (
        <div className="fixed inset-0 bg-[#111827] bg-opacity-75 flex justify-center items-center z-10">
          <SmallSpinner />
        </div>
      )}
      <h1 className="text-2xl font-bold text-[#B9B7BF] mb-20">Dashboard</h1>

      {/* Stats Section */}
      <div className="flex justify-center mb-20">
        <div className="flex flex-wrap justify-between w-full max-w-4xl space-y-4 md:space-y-0">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="bg-[#1F2937] p-4 rounded-lg border border-[#1F2937] text-center h-32 w-full md:w-1/4 flex flex-col justify-center items-center"
            >
              <h2 className="text-xl font-semibold text-[#B9B7BF]">
                {stat.value}
              </h2>
              <p className="text-xs text-[#9CA3AF]">{stat.title}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Activity Feed */}
      <div className="bg-[#1F2937] p-4 rounded-lg shadow-md">
        <div className="flex items-center justify-between mb-4 border-b border-[#464A55] pb-2">
          <h2 className="text-lg font-semibold text-[#B9B7BF]">
            Activity Feed
          </h2>
          <FiMoreHorizontal className="text-[#B9B7BF] text-xl" />
        </div>

        <div className="mt-4 mb-2">
          {activities.map((activity, index) => (
            <div
              key={index}
              className={`py-2 ${
                index !== activities.length - 1
                  ? "border-b border-[#464A55] mt-2 mb-2"
                  : ""
              }`}
            >
              <div className="flex items-center">
                <img
                  src={activity.icon}
                  alt="Activity icon"
                  className="w-6 h-6 mr-6"
                />
                <div className="flex flex-col">
                  <span className="text-[#9CA3AF]">{activity.text}</span>
                  <span className="text-sm text-[#9CA3AF]">
                    {activity.subtext}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardOverview;
