import { useEffect, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import group from "../../assets/images/group.svg";
import SmallSpinner from "../../components/common/SmallSpinner"; // Import SmallSpinner
import { clearTokens } from "../../redux/authSlice";
import { OrganizationApiClient } from "../../services/organization/OrganizationApiClient";
import { UserApiClient } from "../../services/user/UserApiClient";

interface Activity {
  icon: string;
  text: string;
  subtext: string;
}

interface StatsInfo {
  title: string;
  value: number;
}

interface Stats {
  total_count: StatsInfo;
  active_users: StatsInfo;
  pending_users: StatsInfo;
}

const DashboardOverview = () => {
  const token = useSelector((state: any) => state.auth.user_token);
  const orgToken = useSelector((state: any) => state.auth.organization_token);
  const userName = useSelector((state: any) => state.auth.username);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [stats, setStats] = useState<StatsInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const fetchUserCountStats = async () => {
    setLoading(true);
    try {
      let stats: StatsInfo[] = [];
      const apiClient = new OrganizationApiClient().organizationApiService();
      await apiClient
        .getOrganizationCountApiV1OrganizationCountGet()
        .then((response: any) => {
          const data = response.data.organizations;
          if (data && data.length > 0) {
            let total = 0;
            for (let i = 0; i < data.length; i++) {
              if (data[i].onboarding_status === "ACTIVE") {
                stats.push({
                  title: "Active Organization",
                  value: data[i].count ?? 0,
                });
              }

              if (data[i].onboarding_status === "INVITED") {
                stats.push({
                  title: "Pending Invitations",
                  value: data[i].count ?? 0,
                });
              }

              total += data[i].count ?? 0;
            }
            stats.unshift({ title: "Total Organization", value: total });
            setStats(stats);
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching users data:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const fetchUserAudits = async () => {
    setLoading(true); // Start loading
    try {
      var apiClient = new UserApiClient().userAuditApiService();
      await apiClient
        .getAuditsApiV1AuditsUserNameGet(userName)
        .then((response: any) => {
          console.log(response);
          const activities: Activity[] = response.data.map(
            (item: any): Activity => ({
              icon: group,
              text: item.message,
              subtext: item.time,
            })
          );

          setActivities(activities);
        })
        .catch((error: any) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching users data:", error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const RedirectLogin = () => {
    navigate("/admin");
  };

  useEffect(() => {
    console.log("Dashboard");
    console.log(token);
    console.log(userName);

    if (token) {
      fetchUserAudits();
      fetchUserCountStats();
    }

    if (!token || !orgToken) {
      dispatch(clearTokens());
      RedirectLogin();
    }
  }, [token]);

  return (
    <div className="bg-[#111827] p-4 md:p-6 text-[#B9B7BF] min-h-screen overflow-hidden relative">
      {loading && (
        <div className="fixed inset-0 bg-[#111827] bg-opacity-75 flex justify-center items-center z-10">
          <SmallSpinner />
        </div>
      )}

      {/* Dashboard Title */}
      <h1 className="text-xl md:text-2xl font-bold text-[#B9B7BF] mb-12 md:mb-20">
        Dashboard
      </h1>

      {/* Stats Section */}
      <div className="flex justify-center mb-12 md:mb-20">
        <div className="flex flex-wrap justify-center gap-4 w-full max-w-full px-2 sm:px-4 lg:max-w-[1024px]">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="bg-[#1F2937] p-4 rounded-lg border border-[#1F2937] text-center h-32 flex-1 min-w-[150px] sm:min-w-[200px] md:min-w-[250px] lg:max-w-[300px] flex flex-col justify-center items-center"
            >
              <h2 className="text-lg md:text-xl font-semibold text-[#B9B7BF]">
                {stat.value}
              </h2>
              <p className="text-xs text-[#9CA3AF]">{stat.title}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Activity Feed Section */}
      <div className="bg-[#1F2937] p-4 rounded-lg shadow-md">
        <div className="flex items-center justify-between mb-4 border-b border-[#464A55] pb-2">
          <h2 className="text-lg font-semibold text-[#B9B7BF]">
            Activity Feed
          </h2>
          <FiMoreHorizontal className="text-[#B9B7BF] text-xl" />
        </div>

        {/* Activity List */}
        <div className="mt-4 mb-2">
          {activities.map((activity, index) => (
            <div
              key={index}
              className={`py-2 ${
                index !== activities.length - 1
                  ? "border-b border-[#464A55] mt-2 mb-2"
                  : ""
              }`}
            >
              <div className="flex items-center flex-wrap gap-4">
                <img
                  src={activity.icon}
                  alt="Activity icon"
                  className="w-6 h-6"
                />
                <div className="flex flex-col">
                  <span className="text-[#9CA3AF]">{activity.text}</span>
                  <span className="text-sm text-[#9CA3AF]">
                    {activity.subtext}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardOverview;
