import { store } from "../../redux/store";
import { AuditsAPIApi, PermissionsAPIApi, UserRolesAPIApi, UsersAPIApi } from "../../swagger-apis/generated-users-api";
import { GenericApiClientBase } from "../common/GenericApiClientBase";

export class UserApiClient extends GenericApiClientBase {
  base_url = process.env.REACT_APP_API_URL || '';

  protected getAccessToken(): string | null {
    const state = store.getState();
    const token = state?.auth?.user_token;
    return token;
  }

  public userApiService = () => {
    const api = new UsersAPIApi(this.configuration());
    return api;
  }

  public userRoleApiService = () => {
    const api = new UserRolesAPIApi(this.configuration());
    return api;
  }

  public userPermissionApiService = () => {
    const api = new PermissionsAPIApi(this.configuration());
    return api;
  }

  public userAuditApiService = () => {
    const api = new AuditsAPIApi(this.configuration());
    return api;
  }

}
