import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import img from "../../assets/images/logistics.svg";
import Logo from "../../components/common/Logo";
import Spinner from "../../components/common/Spinner";
import { OrganizationApiClient } from "../../services/organization/OrganizationApiClient";
import {
  OrganizationDTO,
  Status,
} from "../../swagger-apis/generated-organization-api";

function Onboard() {
  const [orgCode, setOrgCode] = useState<string>(""); // Field for 3-letter organization code
  const [orgName, setOrgName] = useState<string>(""); // Field for the rest of the organization name
  const [siteName, setSiteName] = useState<string>("");
  const [adminName, setAdminName] = useState<string>("");
  const [adminEmail, setAdminEmail] = useState<string>("");
  const [adminNumber, setAdminNumber] = useState<string>("");
  const [details, setDetails] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state: any) => state.auth.organization_token);
  const [file, setFile] = useState();

  const saveOrganization = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      var apiClient = new OrganizationApiClient().organizationApiService();
      var organization: OrganizationDTO = {
        organization_name: orgName,
        site_url_prefix: siteName,
        admin_id: adminName,
        admin_email: adminEmail,
        admin_phone: adminNumber,
        additional_details: details,
        onboarding_status: Status.Active,
        admin_status: Status.Invited,
      };
      var tenantId = siteName.replace("-", "_").replace(".", "_").toLowerCase();
      if (file) {
        await apiClient
          .addLogoOrganizationApiV1OrganizationsLogoTenantIdPut(tenantId, file)
          .then((response) => {
            console.log("Uploaded logo");
          })
          .catch((error) => {
            toast.error(
              "Failed to upload logo. Still proceeding to onboard organization"
            );
          });
      }

      await apiClient
        .createOrganizationsApiV1OrganizationsPost(organization)
        .then((response) => {
          console.log(response);
          toast.success(
            "Successfully sent email to Organization Admin for further steps"
          );
          clearData();
        })
        .catch((error) => {
          console.log("Organization error", error);
          toast.error("Failed to create organization.");
        });
    } catch (error) {
      console.error("Error fetching users data:", error);
      toast.error("Failed to create organization.");
    } finally {
      setIsLoading(false);
    }
  };

  const uploadImage = (e: any) => {
    console.log(e.target.files);
    setFile(e.target.files[0]);
  };

  const clearData = () => {
    setOrgCode("");
    setOrgName("");
    setSiteName("");
    setAdminName("");
    setAdminEmail("");
    setAdminNumber("");
    setDetails("");
  };

  const navigate = useNavigate();

  const handleContinue = () => {
    navigate("/admin");
  };

  useEffect(() => {
    if (!token) {
      clearData();
      handleContinue();
    }
  }, []);

  const handleOrgCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    if (value.length <= 3) {
      setOrgCode(value);
    }
    setOrgName(value);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#111827] p-4 md:p-6">
      <div className="bg-[#1F2937] p-6 md:p-8 shadow-lg w-full max-w-3xl mx-auto">
        <div className="flex flex-col items-center mb-8">
          <Logo
            className="w-36 h-36 md:w-48 md:h-48 mx-auto"
            imagePath={img}
          />
          <h2 className="text-xl md:text-2xl font-bold text-blue-300 mt-2">
            Setup Organization
          </h2>
        </div>
        <form className="space-y-4">
          {/* Organization Name */}
          <div>
            <label
              className="block text-base md:text-xl text-[#9ca3af]"
              htmlFor="org-name"
            >
              Organization Name
            </label>
            <div className="flex flex-col md:flex-row items-center gap-4">
              {file ? (
                <img
                  className="mt-1 block border border-[#374151] rounded-md px-2 py-1 md:p-2 bg-[#1f2937] text-[#374151] w-24 md:w-1/12"
                  src={URL.createObjectURL(file)}
                  alt="Organization"
                />
              ) : (
                orgCode.length > 0 && (
                  <input
                    type="text"
                    id="org-code"
                    className="mt-1 block border border-[#374151] rounded-md px-2 py-1 md:p-2 bg-[#1f2937] text-[#374151] w-24 md:w-1/12"
                    value={orgCode}
                    maxLength={3}
                    disabled
                  />
                )
              )}
              <input
                type="text"
                id="org-name"
                placeholder=""
                className="mt-1 block w-full border border-[#374151] rounded-md px-2 py-1 md:p-2 bg-[#1f2937] text-[#374151]"
                value={orgName}
                onChange={handleOrgCodeChange}
              />
            </div>
          </div>

          {/* Upload Logo Button */}
          <div className="flex justify-center items-center mb-4">
            <input
              type="file"
              className="border border-[#3485E2] text-[#3485E2] hover:bg-blue-700 rounded-md px-2 py-1 md:p-2 w-full max-w-xs"
              style={{ height: "42px" }}
              onChange={uploadImage}
            />
          </div>

          {/* Site Name */}
          <div>
            <label
              className="block text-base md:text-xl text-[#9ca3af]"
              htmlFor="site-name"
            >
              Site Name
            </label>
            <div className="relative inline-block w-full">
              <input
                type="text"
                id="site-name"
                className="mt-1 block w-full border border-[#374151] rounded-md px-2 py-1 md:p-2 bg-[#1f2937] text-[#374151] pr-20"
                value={siteName}
                onChange={(e) => setSiteName(e.target.value)}
              />
              {siteName.length > 0 && (
                <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400">
                  .logisticslab.ca
                </span>
              )}
            </div>
          </div>

          {/* Admin Name */}
          <div>
            <label
              className="block text-base md:text-xl text-[#9ca3af]"
              htmlFor="admin-name"
            >
              Admin Name
            </label>
            <input
              type="text"
              id="admin-name"
              className="mt-1 block w-full border border-[#374151] rounded-md px-2 py-1 md:p-2 bg-[#1f2937] text-[#374151]"
              value={adminName}
              onChange={(e) => setAdminName(e.target.value)}
            />
          </div>

          {/* Admin Email Address */}
          <div>
            <label
              className="block text-base md:text-xl text-[#9ca3af]"
              htmlFor="admin-email"
            >
              Admin Email Address
            </label>
            <input
              type="email"
              id="admin-email"
              className="mt-1 block w-full border border-[#374151] rounded-md px-2 py-1 md:p-2 bg-[#1f2937] text-[#374151]"
              value={adminEmail}
              onChange={(e) => setAdminEmail(e.target.value)}
            />
          </div>

          {/* Admin Phone Number */}
          <div>
            <label
              className="block text-base md:text-xl text-[#9ca3af]"
              htmlFor="admin-phone"
            >
              Admin Phone Number
            </label>
            <input
              type="tel"
              id="admin-phone"
              className="mt-1 block w-full border border-[#374151] rounded-md px-2 py-1 md:p-2 bg-[#1f2937] text-[#374151]"
              value={adminNumber}
              onChange={(e) => setAdminNumber(e.target.value)}
            />
          </div>

          {/* Additional Details */}
          <div>
            <label
              className="block text-base md:text-xl text-[#9ca3af]"
              htmlFor="additional-details"
            >
              Additional Details
            </label>
            <textarea
              id="additional-details"
              className="mt-1 block w-full border border-[#374151] rounded-md px-2 py-1 md:p-2 bg-[#1f2937] text-[#374151]"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            ></textarea>
          </div>

          {/* Submit Button */}
          <div className="flex justify-center mb-4">
            {isLoading ? (
              <Spinner message="Creating Organization" />
            ) : (
              <button
                type="submit"
                className="bg-[#3485E2] text-white hover:bg-blue-700 rounded-md px-2 py-1 md:p-2 w-full max-w-xs"
                style={{ height: "42px" }}
                disabled={
                  !orgCode ||
                  !orgName ||
                  !siteName ||
                  !adminName ||
                  !adminEmail ||
                  !adminNumber ||
                  !details
                }
                onClick={saveOrganization}
              >
                Create
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Onboard;
