import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_token: null,
  isAuthenticated: false,
  username: null,
  organization_token: null,
  tenant_id: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserToken: (state, action) => {
      state.user_token = action.payload.access_token;
      state.isAuthenticated = !!action.payload;
    },
    clearUserToken: (state) => {
      state.user_token = null;
      state.isAuthenticated = false;
    },
    setOrganizationToken: (state, action) => {
      state.organization_token = action.payload.access_token;
      state.isAuthenticated = !!action.payload;
    },
    clearOrganizationToken: (state) => {
      state.organization_token = null;
      state.isAuthenticated = false;
    },

    setUserName: (state, action) => {
      state.username = action.payload;
    },

    clearTokens: (state) => {
      state.organization_token = null;
      state.user_token = null;
      state.isAuthenticated = false;
    },

    setTenantId: (state, action) => {
      state.tenant_id = action.payload;
    }
  },
});

export const { setUserToken, clearUserToken,setOrganizationToken,clearOrganizationToken, setUserName, clearTokens,setTenantId } = authSlice.actions;
export default authSlice.reducer;
