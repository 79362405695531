import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/ums/Dashboard";

function UmsRoutes() {
  return (
    <Routes>
      <Route path="/dashboard/*" element={<Dashboard />} />
    </Routes>
  );
}

export default UmsRoutes;













