import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SmallSpinner from "../../components/common/SmallSpinner"; // Import SmallSpinner
import { UserApiClient } from "../../services/user/UserApiClient";

const permissions1 = [
  { text: "View All Orders" },
  { text: "View Past Orders" },
  { text: "View All routes" },
  { text: "View Past Deliveries" },
  { text: "View All Reports" },
  { text: "View All routes" },
  { text: "View All routes" },
  { text: "View All routes" },
  { text: "View All routes" },
  { text: "View All routes" },
  { text: "View All routes" },
  { text: "View All routes" },
];

function AccessPermissions() {
  const token = useSelector((state: any) => state.auth.user_token);
  const userName = useSelector((state: any) => state.auth.username);
  const [roles, setRoles] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<string[]>();
  const [selectedRole, setSelectedRole] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchUserPermissions = async (role: string) => {
    setLoading(true); // Start loading
    try {
      var apiClient = new UserApiClient().userPermissionApiService();
      await apiClient
        .getPermissionsApiV1PermissionsRoleGet(role)
        .then((response) => {
          if (response.data.permissions) {
            const permissions = response.data.permissions.map(
              (item: any): string => {
                return item.action + " " + item.resource;
              }
            );
            setPermissions(permissions);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching roles data:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const navigate = useNavigate();
  const RedirectLogin = () => {
    navigate("/admin");
  };

  useEffect(() => {
    console.log("Permission");
    console.log(token);

    const fetchUserRoles = async () => {
      setLoading(true); // Start loading
      try {
        var apiClient = new UserApiClient().userRoleApiService();
        await apiClient
          .getUsersApiV1UserRolesUserNameGet(userName)
          .then((response) => {
            if (response.data.user_roles) {
              setRoles(response.data.user_roles);
              fetchUserPermissions(response.data.user_roles[0]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error("Error fetching roles data:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    if (token) {
      fetchUserRoles();
    }

    if (!token) {
      RedirectLogin();
    }
  }, [token]);

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedRole(value);
    console.log("Selected Value:", value);
    fetchUserPermissions(value);
  };

  return (
    <div className="bg-[#111827] p-6 text-[#B9B7BF] min-h-screen overflow-hidden relative">
      {loading && (
        <div className="fixed inset-0 bg-[#111827] bg-opacity-75 flex justify-center items-center z-10">
          <SmallSpinner />
        </div>
      )}
      <h1 className="text-2xl text-[#B9B7BF] mb-10">
        Edit User Access Permissions
      </h1>
      <div className="flex flex-col w-full mb-8 lg:mb-20">
        <h2 className="text-xl text-[#B9B7BF] mb-2">Select role</h2>
        <select
          id="roleSelect"
          className="bg-[#111827] text-[#B9B7BF] border border-[#374151] rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-300 w-full sm:w-[60%] md:w-[40%] lg:w-[30%]"
          value={selectedRole}
          onChange={handleRoleChange}
        >
          {roles.map((role, index) => (
            <option
              value={role}
              key={role}
            >
              {role}
            </option>
          ))}
        </select>
      </div>

      <h2 className="text-2xl text-[#B9B7BF] mb-5">Permissions</h2>
      <div className="bg-[#1F2937] p-8 lg:p-12  rounded-lg shadow-md mb-5">
        <div className="mt-4 mb-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 sm:gap-8 md:gap-10">
          {permissions?.map((permission, index) => (
            <div
              className="flex items-center"
              key={index}
            >
              <input
                type="checkbox"
                id={`checkbox-${index}`}
                key={`checkbox-${index}`}
                checked={true}
                disabled={true}
                className="h-4 w-4 text-blue-500 bg-[#1F2937] border-[#374151] rounded focus:ring-blue-300"
              />
              <label
                key={`checkbox-${index}`}
                htmlFor={`checkbox-${index}`}
                className="ml-2 text-[#B9B7BF]"
              >
                {permission}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center">
        <button
          disabled={true}
          className={`${
            true ? "bg-gray-400 border-gray-500" : "bg-blue-500 border-blue-700"
          } cursor-not-allowed text-white rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-300 w-full sm:w-1/3 md:w-1/4 lg:w-1/6`}
        >
          Save Permissions
        </button>
      </div>
    </div>
  );
}

export default AccessPermissions;
