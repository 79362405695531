import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import AdminSidebar from "../../components/common/AdminSidebar";
import Header from "../../components/common/Header";
import AccessPermissions from "./AccessPermissions";
import ManageUsers from "./ManageUsers";
import DashboardOverview from "./Overview";

// Define the Links type
type LinkType = {
  name: string;
  path: string;
};

const Dashboard = () => {
  const token = useSelector((state: any) => state.auth.user_token);
  const [links, setLinks] = useState<LinkType[]>([]);

  const navigate = useNavigate();
  const RedirectLogin = () => {
    navigate("/");
  };

  useEffect(() => {
    if (token) {
      const linksArray: LinkType[] = [];
      linksArray.push({
        name: "Dashboard",
        path: "",
      });

      linksArray.push({
        name: "Manage Users",
        path: "manage",
      });

      linksArray.push({
        name: "Access Permissions",
        path: "permission",
      });
      setLinks(linksArray);
    }

    if (!token) {
      RedirectLogin();
    }
  }, [token]);

  const navigationSections = [
    {
      title: "USER MANAGEMENT",
      links: links.length
        ? links
        : [
            { name: "Dashboard", path: "" },
            { name: "Manage Users", path: "manage" },
            { name: "Access Permissions", path: "permission" },
          ],
    },
  ];

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <Header logoutRedirectionPath="/" />

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar: Hidden on small screens, visible on medium and larger screens */}
        <AdminSidebar
          navigationSections={navigationSections}
          className="md:flex md:w-64 lg:max-h-full bg-gray-200"
          logoutRedirectionPath="/"
        />

        {/* Main Content Area */}
        <main className="flex-1 bg-gray-100 overflow-auto custom-scrollbar">
          <Routes>
            <Route
              path=""
              element={<DashboardOverview />}
            />
            <Route
              path="manage"
              element={<ManageUsers />}
            />
            <Route
              path="permission"
              element={<AccessPermissions />}
            />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
