import axios from "axios";
import { setUserName } from "../../redux/authSlice";
import { AppDispatch } from "../../redux/store";
import { IOAuthTokenClient } from "./IOAuthTokenClient";

export abstract class OAuthTokenClientBase implements IOAuthTokenClient {
    protected token: string | null = null;
    abstract base_url: string;
    abstract client_id: string;
    abstract client_secret: string;

    // Common implementation that uses class-specific properties
    async GetOAuth2Token(dispatch: AppDispatch, username: string, password: string, scope?: string): Promise<void> {
        try {

            const formData = new URLSearchParams();
            formData.append('grant_type', 'password');
            formData.append('username', username);
            formData.append('password', password);
            formData.append('scope', scope ? scope : (process.env.REACT_APP_TENANT || ""));
            formData.append('client_id', this.client_id);
            formData.append('client_secret', this.client_secret);

            const response = await axios.post(`${this.base_url}/oauth2/token`, formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            const token = response.data;
            console.log(token);
            this.token = token;
            dispatch(setUserName(username));
            console.log('Saved token');

        } catch (error) {
            console.error('Failed to fetch OAuth2 token', error);
            throw error;
        }

    }
}
