import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';

// Combine your reducers
const rootReducer = combineReducers({
  auth: authReducer,
});

// Define your RootState type
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
