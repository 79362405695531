import React from "react";
import x from "../../assets/images/x.svg"; // Importing the x image
import { UserDTO } from "../../swagger-apis/generated-users-api";

interface AccessDeniedModalProps {
  onClose: () => void;
  data: UserDTO;
}

const DetailsModal: React.FC<AccessDeniedModalProps> = ({ data, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-[#5F6670] bg-opacity-50">
      <div className="relative bg-[#111827] rounded-lg shadow-lg p-6 sm:p-8 w-full max-w-lg sm:max-w-xl md:max-w-2xl min-h-[300px] sm:min-h-[400px] mx-4">
        <img
          src={x}
          alt="close"
          className="absolute top-4 right-4 w-6 h-6 cursor-pointer"
          onClick={onClose}
        />
        <h2 className="text-lg sm:text-xl font-semibold text-[#B9B7BF] mb-6 sm:mb-8 text-center">
          User Details
        </h2>
        <table className="min-w-full">
          <tbody>
            <tr>
              <td className="py-3 sm:py-4 px-4 sm:px-6 font-semibold text-[#5D5E68]">
                Username:
              </td>
              <td className="py-3 sm:py-4 px-4 sm:px-6 text-[#B9B7BF]">
                {data.user_name}
              </td>
            </tr>
            <tr>
              <td className="py-3 sm:py-4 px-4 sm:px-6 font-semibold text-[#5D5E68]">
                Email Address:
              </td>
              <td className="py-3 sm:py-4 px-4 sm:px-6 text-[#B9B7BF]">
                {data.email}
              </td>
            </tr>
            <tr>
              <td className="py-3 sm:py-4 px-4 sm:px-6 font-semibold text-[#5D5E68]">
                Phone Number:
              </td>
              <td className="py-3 sm:py-4 px-4 sm:px-6 text-[#B9B7BF]">
                {data.phone_number}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailsModal;
