import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import img from "../../assets/images/logistics.svg";
import Logo from "../../components/common/Logo";
import Spinner from "../../components/common/Spinner";
import { setTenantId } from "../../redux/authSlice";
import { UserTokenClient } from "../../services/user/UserTokenClient";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState<string>("");
  const dispatch = useDispatch();
  const [tenant, setTenant] = useState<string>("");

  const location = useLocation();
  const token = useSelector((state: any) => state.auth.organization_token);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    var userApiClient = new UserTokenClient();

    try {
      if (tenant) {
        await userApiClient.GetOAuth2Token(
          dispatch,
          username,
          password,
          "tenant:" + tenant
        );
        console.log("Validated credentials");

        handleNavigate();
      }
    } catch (error) {
      console.error("User token error:", error);
      toast.error("Invalid username or password.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    const cookieName = `user_selection_${username}`;
    const existingCookie = Cookies.get(cookieName);

    if (existingCookie) {
      if (existingCookie === "user-management") {
        navigate("/ums/dashboard");
      } else if (existingCookie == "order-management") {
        navigate("/oms/dashboard");
      } else if (existingCookie == "user-management") {
        navigate("/tms/dashboard");
      } else {
        navigate("welcome");
      }
    } else {
      navigate("welcome");
    }
  };

  useEffect(() => {
    if (!tenant) {
      let tenantId;
      if (process.env.REACT_APP_ENVIRONMENT === "local") {
        const queryParams = new URLSearchParams(location.search);
        tenantId = queryParams.get("tenantId")
          ? queryParams.get("tenantId")
          : "";
      } else {
        const hostname = window.location.hostname;
        tenantId = hostname.split(".")[0]; // Extract 'abc' from 'abc.example.com'
      }

      console.log(tenantId);
      dispatch(setTenantId(tenantId));
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#111827] p-4">
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className="mt-2"></div>
      <div
        className="bg-[#1F2937] p-8 shadow-lg rounded-lg mt-4 flex flex-col justify-between mb-6"
        style={{
          width: "100%",
          maxWidth: "600px",
          height: "auto",
          maxHeight: "720px",
        }}
      >
        <div>
          <h1 className="text-2xl font-semibold text-center text-blue-300 mb-4">
            Welcome to LogisticsLab
          </h1>
          <div className="flex justify-center mb-6">
            <Logo imagePath={img} />
          </div>
          <h2 className="text-lg font-medium text-center text-blue-300 mb-6">
            Login
          </h2>
          <form className="mt-6">
            <div className="mb-4">
              <label
                htmlFor="login-id"
                className="block text-sm text-[#B9B7BF]"
              >
                Login ID
              </label>
              <input
                type="text"
                id="login-id"
                className="mt-1 block w-full px-2 py-1 border border-[#374151] rounded-md bg-[#111827] text-[#B9B7BF] md:p-2"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm text-[#B9B7BF]"
              >
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="mt-1 block w-full px-2 py-1 border border-[#374151] rounded-md bg-[#111827] text-[#B9B7BF] md:p-2"
                  placeholder="********"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {password.length > 0 && (
                  <button
                    type="button"
                    className="absolute right-2 top-0.5 md:top-1.5"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28px"
                        height="28px"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                          stroke="#3483e2"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28px"
                        height="28px"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                          stroke="#3483e2"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                          stroke="#3483e2"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm text-[#B9B7BF]"
              >
                Tenant
              </label>
              <input
                type="text"
                id="tenant"
                className="mt-1 block w-full px-2 py-1 border border-[#374151] rounded-md bg-[#111827] text-[#B9B7BF] md:p-2"
                required
                value={tenant}
                onChange={(e) => setTenant(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="flex justify-center mt-12">
          {isLoading ? (
            <Spinner message="Validating your credentials" />
          ) : (
            <button
              disabled={password.length <= 0 && username.length <= 0}
              onClick={handleSubmit}
              className="bg-[#3485E2] text-white hover:bg-blue-700 p-2 rounded-lg w-full max-w-[350px]"
            >
              Continue
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
