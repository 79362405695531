/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * <font color=\"green\"><b>Environment: DEV</b><hr/></font><br/>The Users API authenticates, authorizes, onboards, manages users and their roles and permissions.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@logisticslabs.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface PermissionDTO
 */
export interface PermissionDTO {
    /**
     * 
     * @type {string}
     * @memberof PermissionDTO
     */
    'resource': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionDTO
     */
    'action': string;
}
/**
 * 
 * @export
 * @interface PermissionGETResponseDTO
 */
export interface PermissionGETResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof PermissionGETResponseDTO
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof PermissionGETResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {Array<PermissionDTO>}
     * @memberof PermissionGETResponseDTO
     */
    'permissions': Array<PermissionDTO>;
}
/**
 * 
 * @export
 * @interface PermissionPOSTResponseDTO
 */
export interface PermissionPOSTResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof PermissionPOSTResponseDTO
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof PermissionPOSTResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionPOSTResponseDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface PermissionRoleAttachmentResponseDTO
 */
export interface PermissionRoleAttachmentResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof PermissionRoleAttachmentResponseDTO
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof PermissionRoleAttachmentResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionRoleAttachmentResponseDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface PermissionRoleDTO
 */
export interface PermissionRoleDTO {
    /**
     * 
     * @type {UserRole}
     * @memberof PermissionRoleDTO
     */
    'role': UserRole;
    /**
     * 
     * @type {string}
     * @memberof PermissionRoleDTO
     */
    'permission_id': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const Status = {
    Active: 'ACTIVE',
    Disabled: 'DISABLED',
    Invited: 'INVITED'
} as const;

export type Status = typeof Status[keyof typeof Status];


/**
 * 
 * @export
 * @interface TokenDTO
 */
export interface TokenDTO {
    /**
     * 
     * @type {string}
     * @memberof TokenDTO
     */
    'client_id': string;
    /**
     * 
     * @type {string}
     * @memberof TokenDTO
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof TokenDTO
     */
    'token_type': string;
}
/**
 * 
 * @export
 * @interface UserCountDTO
 */
export interface UserCountDTO {
    /**
     * 
     * @type {string}
     * @memberof UserCountDTO
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserCountDTO
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface UserCountResponseDTO
 */
export interface UserCountResponseDTO {
    /**
     * 
     * @type {Array<UserCountDTO>}
     * @memberof UserCountResponseDTO
     */
    'data'?: Array<UserCountDTO>;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'user_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'organization_name': string;
    /**
     * 
     * @type {Status}
     * @memberof UserDTO
     */
    'status'?: Status;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    'is_phone_verified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    'is_mfa_set'?: boolean;
}


/**
 * 
 * @export
 * @interface UserGETResponseDTO
 */
export interface UserGETResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof UserGETResponseDTO
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof UserGETResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof UserGETResponseDTO
     */
    'count': number;
    /**
     * 
     * @type {Array<UserResponseDTO>}
     * @memberof UserGETResponseDTO
     */
    'users'?: Array<UserResponseDTO>;
}
/**
 * 
 * @export
 * @interface UserInviteDTO
 */
export interface UserInviteDTO {
    /**
     * 
     * @type {string}
     * @memberof UserInviteDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserInviteDTO
     */
    'organization_name': string;
}
/**
 * 
 * @export
 * @interface UserPATCHResponseDTO
 */
export interface UserPATCHResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof UserPATCHResponseDTO
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof UserPATCHResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof UserPATCHResponseDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface UserPOSTReponseDTO
 */
export interface UserPOSTReponseDTO {
    /**
     * 
     * @type {number}
     * @memberof UserPOSTReponseDTO
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof UserPOSTReponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof UserPOSTReponseDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface UserPasswordDTO
 */
export interface UserPasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof UserPasswordDTO
     */
    'current_password': string;
    /**
     * 
     * @type {string}
     * @memberof UserPasswordDTO
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface UserPatchDTO
 */
export interface UserPatchDTO {
    /**
     * 
     * @type {string}
     * @memberof UserPatchDTO
     */
    'user_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatchDTO
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatchDTO
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatchDTO
     */
    'address'?: string;
    /**
     * 
     * @type {Status}
     * @memberof UserPatchDTO
     */
    'status'?: Status;
    /**
     * 
     * @type {string}
     * @memberof UserPatchDTO
     */
    'phone_number'?: string;
}


/**
 * 
 * @export
 * @interface UserPermissionsDTO
 */
export interface UserPermissionsDTO {
    /**
     * 
     * @type {string}
     * @memberof UserPermissionsDTO
     */
    'user_name': string;
    /**
     * 
     * @type {Array<PermissionDTO>}
     * @memberof UserPermissionsDTO
     */
    'permissions': Array<PermissionDTO>;
    /**
     * 
     * @type {string}
     * @memberof UserPermissionsDTO
     */
    'tenant_id': string;
}
/**
 * 
 * @export
 * @interface UserResponseDTO
 */
export interface UserResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'user_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'organization_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponseDTO
     */
    'is_phone_verified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponseDTO
     */
    'is_mfa_set': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'password_date_until_expiry': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserRole = {
    OrgAdmin: 'ORG_ADMIN',
    SuperUser: 'SUPER_USER',
    Dispatcher: 'DISPATCHER',
    Driver: 'DRIVER',
    Customer: 'CUSTOMER',
    CustomerService: 'CUSTOMER_SERVICE'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];


/**
 * 
 * @export
 * @interface UserRoleDTO
 */
export interface UserRoleDTO {
    /**
     * 
     * @type {string}
     * @memberof UserRoleDTO
     */
    'user_name': string;
    /**
     * 
     * @type {UserRole}
     * @memberof UserRoleDTO
     */
    'role': UserRole;
}


/**
 * 
 * @export
 * @interface UserRoleGETResponseDTO
 */
export interface UserRoleGETResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof UserRoleGETResponseDTO
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof UserRoleGETResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRoleGETResponseDTO
     */
    'user_roles'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserRolePATCHResponseDTO
 */
export interface UserRolePATCHResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof UserRolePATCHResponseDTO
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof UserRolePATCHResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof UserRolePATCHResponseDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * AuditsAPIApi - axios parameter creator
 * @export
 */
export const AuditsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Audits
         * @param {string} userName 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditsApiV1AuditsUserNameGet: async (userName: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('getAuditsApiV1AuditsUserNameGet', 'userName', userName)
            const localVarPath = `/api/v1/audits/{user_name}`
                .replace(`{${"user_name"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditsAPIApi - functional programming interface
 * @export
 */
export const AuditsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Audits
         * @param {string} userName 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditsApiV1AuditsUserNameGet(userName: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditsApiV1AuditsUserNameGet(userName, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditsAPIApi.getAuditsApiV1AuditsUserNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuditsAPIApi - factory interface
 * @export
 */
export const AuditsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditsAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Audits
         * @param {string} userName 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditsApiV1AuditsUserNameGet(userName: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getAuditsApiV1AuditsUserNameGet(userName, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditsAPIApi - object-oriented interface
 * @export
 * @class AuditsAPIApi
 * @extends {BaseAPI}
 */
export class AuditsAPIApi extends BaseAPI {
    /**
     * 
     * @summary Get Audits
     * @param {string} userName 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsAPIApi
     */
    public getAuditsApiV1AuditsUserNameGet(userName: string, limit?: number, options?: RawAxiosRequestConfig) {
        return AuditsAPIApiFp(this.configuration).getAuditsApiV1AuditsUserNameGet(userName, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OAuth2Api - axios parameter creator
 * @export
 */
export const OAuth2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
         * @summary This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginOauth2TokenPost: async (username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginOauth2TokenPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginOauth2TokenPost', 'password', password)
            const localVarPath = `/oauth2/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
         * @summary This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginOauth2TokenPost_1: async (username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginOauth2TokenPost_1', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginOauth2TokenPost_1', 'password', password)
            const localVarPath = `/oauth2/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersMeUsersMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersMeUsersMeGet_2: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OAuth2Api - functional programming interface
 * @export
 */
export const OAuth2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OAuth2ApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
         * @summary This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginOauth2TokenPost(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginOauth2TokenPost(username, password, grantType, scope, clientId, clientSecret, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuth2Api.loginOauth2TokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
         * @summary This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginOauth2TokenPost_1(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginOauth2TokenPost_1(username, password, grantType, scope, clientId, clientSecret, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuth2Api.loginOauth2TokenPost_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUsersMeUsersMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPermissionsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUsersMeUsersMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuth2Api.readUsersMeUsersMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUsersMeUsersMeGet_2(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPermissionsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUsersMeUsersMeGet_2(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuth2Api.readUsersMeUsersMeGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OAuth2Api - factory interface
 * @export
 */
export const OAuth2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OAuth2ApiFp(configuration)
    return {
        /**
         * This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
         * @summary This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginOauth2TokenPost(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<TokenDTO> {
            return localVarFp.loginOauth2TokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
         * @summary This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginOauth2TokenPost_1(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<TokenDTO> {
            return localVarFp.loginOauth2TokenPost_1(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersMeUsersMeGet(options?: RawAxiosRequestConfig): AxiosPromise<UserPermissionsDTO> {
            return localVarFp.readUsersMeUsersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersMeUsersMeGet_2(options?: RawAxiosRequestConfig): AxiosPromise<UserPermissionsDTO> {
            return localVarFp.readUsersMeUsersMeGet_2(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OAuth2Api - object-oriented interface
 * @export
 * @class OAuth2Api
 * @extends {BaseAPI}
 */
export class OAuth2Api extends BaseAPI {
    /**
     * This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
     * @summary This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
     * @param {string} username 
     * @param {string} password 
     * @param {string | null} [grantType] 
     * @param {string} [scope] 
     * @param {string | null} [clientId] 
     * @param {string | null} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    public loginOauth2TokenPost(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig) {
        return OAuth2ApiFp(this.configuration).loginOauth2TokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
     * @summary This endpoint is used to obtain an OAuth2 token using the password grant type. It requires the user_name and password of the user.
     * @param {string} username 
     * @param {string} password 
     * @param {string | null} [grantType] 
     * @param {string} [scope] 
     * @param {string | null} [clientId] 
     * @param {string | null} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    public loginOauth2TokenPost_1(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig) {
        return OAuth2ApiFp(this.configuration).loginOauth2TokenPost_1(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Users Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    public readUsersMeUsersMeGet(options?: RawAxiosRequestConfig) {
        return OAuth2ApiFp(this.configuration).readUsersMeUsersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Users Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    public readUsersMeUsersMeGet_2(options?: RawAxiosRequestConfig) {
        return OAuth2ApiFp(this.configuration).readUsersMeUsersMeGet_2(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PermissionsAPIApi - axios parameter creator
 * @export
 */
export const PermissionsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Permission
         * @param {PermissionDTO} permissionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPermissionApiV1PermissionsPost: async (permissionDTO: PermissionDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'permissionDTO' is not null or undefined
            assertParamExists('addPermissionApiV1PermissionsPost', 'permissionDTO', permissionDTO)
            const localVarPath = `/api/v1/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(permissionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Attach Role Permissions
         * @param {PermissionRoleDTO} permissionRoleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachRolePermissionsApiV1PermissionsRoleAttachmentPost: async (permissionRoleDTO: PermissionRoleDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'permissionRoleDTO' is not null or undefined
            assertParamExists('attachRolePermissionsApiV1PermissionsRoleAttachmentPost', 'permissionRoleDTO', permissionRoleDTO)
            const localVarPath = `/api/v1/permissions/role-attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(permissionRoleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Permissions
         * @param {string} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsApiV1PermissionsRoleGet: async (role: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('getPermissionsApiV1PermissionsRoleGet', 'role', role)
            const localVarPath = `/api/v1/permissions/{role}`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsAPIApi - functional programming interface
 * @export
 */
export const PermissionsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Permission
         * @param {PermissionDTO} permissionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPermissionApiV1PermissionsPost(permissionDTO: PermissionDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionPOSTResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPermissionApiV1PermissionsPost(permissionDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsAPIApi.addPermissionApiV1PermissionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Attach Role Permissions
         * @param {PermissionRoleDTO} permissionRoleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachRolePermissionsApiV1PermissionsRoleAttachmentPost(permissionRoleDTO: PermissionRoleDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionRoleAttachmentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachRolePermissionsApiV1PermissionsRoleAttachmentPost(permissionRoleDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsAPIApi.attachRolePermissionsApiV1PermissionsRoleAttachmentPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Permissions
         * @param {string} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionsApiV1PermissionsRoleGet(role: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionGETResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissionsApiV1PermissionsRoleGet(role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsAPIApi.getPermissionsApiV1PermissionsRoleGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PermissionsAPIApi - factory interface
 * @export
 */
export const PermissionsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionsAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Permission
         * @param {PermissionDTO} permissionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPermissionApiV1PermissionsPost(permissionDTO: PermissionDTO, options?: RawAxiosRequestConfig): AxiosPromise<PermissionPOSTResponseDTO> {
            return localVarFp.addPermissionApiV1PermissionsPost(permissionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Attach Role Permissions
         * @param {PermissionRoleDTO} permissionRoleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachRolePermissionsApiV1PermissionsRoleAttachmentPost(permissionRoleDTO: PermissionRoleDTO, options?: RawAxiosRequestConfig): AxiosPromise<PermissionRoleAttachmentResponseDTO> {
            return localVarFp.attachRolePermissionsApiV1PermissionsRoleAttachmentPost(permissionRoleDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Permissions
         * @param {string} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsApiV1PermissionsRoleGet(role: string, options?: RawAxiosRequestConfig): AxiosPromise<PermissionGETResponseDTO> {
            return localVarFp.getPermissionsApiV1PermissionsRoleGet(role, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsAPIApi - object-oriented interface
 * @export
 * @class PermissionsAPIApi
 * @extends {BaseAPI}
 */
export class PermissionsAPIApi extends BaseAPI {
    /**
     * 
     * @summary Add Permission
     * @param {PermissionDTO} permissionDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsAPIApi
     */
    public addPermissionApiV1PermissionsPost(permissionDTO: PermissionDTO, options?: RawAxiosRequestConfig) {
        return PermissionsAPIApiFp(this.configuration).addPermissionApiV1PermissionsPost(permissionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Attach Role Permissions
     * @param {PermissionRoleDTO} permissionRoleDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsAPIApi
     */
    public attachRolePermissionsApiV1PermissionsRoleAttachmentPost(permissionRoleDTO: PermissionRoleDTO, options?: RawAxiosRequestConfig) {
        return PermissionsAPIApiFp(this.configuration).attachRolePermissionsApiV1PermissionsRoleAttachmentPost(permissionRoleDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Permissions
     * @param {string} role 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsAPIApi
     */
    public getPermissionsApiV1PermissionsRoleGet(role: string, options?: RawAxiosRequestConfig) {
        return PermissionsAPIApiFp(this.configuration).getPermissionsApiV1PermissionsRoleGet(role, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserRolesAPIApi - axios parameter creator
 * @export
 */
export const UserRolesAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Attach User Role
         * @param {UserRoleDTO} userRoleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachUserRoleApiV1UserRolesPost: async (userRoleDTO: UserRoleDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRoleDTO' is not null or undefined
            assertParamExists('attachUserRoleApiV1UserRolesPost', 'userRoleDTO', userRoleDTO)
            const localVarPath = `/api/v1/user-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRoleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersApiV1UserRolesUserNameGet: async (userName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('getUsersApiV1UserRolesUserNameGet', 'userName', userName)
            const localVarPath = `/api/v1/user-roles/{user_name}`
                .replace(`{${"user_name"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRolesAPIApi - functional programming interface
 * @export
 */
export const UserRolesAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRolesAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Attach User Role
         * @param {UserRoleDTO} userRoleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachUserRoleApiV1UserRolesPost(userRoleDTO: UserRoleDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRolePATCHResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachUserRoleApiV1UserRolesPost(userRoleDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserRolesAPIApi.attachUserRoleApiV1UserRolesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Users
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersApiV1UserRolesUserNameGet(userName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleGETResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersApiV1UserRolesUserNameGet(userName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserRolesAPIApi.getUsersApiV1UserRolesUserNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserRolesAPIApi - factory interface
 * @export
 */
export const UserRolesAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRolesAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Attach User Role
         * @param {UserRoleDTO} userRoleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachUserRoleApiV1UserRolesPost(userRoleDTO: UserRoleDTO, options?: RawAxiosRequestConfig): AxiosPromise<UserRolePATCHResponseDTO> {
            return localVarFp.attachUserRoleApiV1UserRolesPost(userRoleDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Users
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersApiV1UserRolesUserNameGet(userName: string, options?: RawAxiosRequestConfig): AxiosPromise<UserRoleGETResponseDTO> {
            return localVarFp.getUsersApiV1UserRolesUserNameGet(userName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRolesAPIApi - object-oriented interface
 * @export
 * @class UserRolesAPIApi
 * @extends {BaseAPI}
 */
export class UserRolesAPIApi extends BaseAPI {
    /**
     * 
     * @summary Attach User Role
     * @param {UserRoleDTO} userRoleDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesAPIApi
     */
    public attachUserRoleApiV1UserRolesPost(userRoleDTO: UserRoleDTO, options?: RawAxiosRequestConfig) {
        return UserRolesAPIApiFp(this.configuration).attachUserRoleApiV1UserRolesPost(userRoleDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Users
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesAPIApi
     */
    public getUsersApiV1UserRolesUserNameGet(userName: string, options?: RawAxiosRequestConfig) {
        return UserRolesAPIApiFp(this.configuration).getUsersApiV1UserRolesUserNameGet(userName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersAPIApi - axios parameter creator
 * @export
 */
export const UsersAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel User Invite
         * @param {UserInviteDTO} userInviteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUserInviteApiV1UserInviteDelete: async (userInviteDTO: UserInviteDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userInviteDTO' is not null or undefined
            assertParamExists('cancelUserInviteApiV1UserInviteDelete', 'userInviteDTO', userInviteDTO)
            const localVarPath = `/api/v1/user-invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInviteDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApiV1UsersPost: async (userDTO: UserDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDTO' is not null or undefined
            assertParamExists('createUserApiV1UsersPost', 'userDTO', userDTO)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users
         * @param {Status} [status] 
         * @param {string} [userName] 
         * @param {string} [userEmail] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersApiV1UsersGet: async (status?: Status, userName?: string, userEmail?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (userName !== undefined) {
                localVarQueryParameter['user_name'] = userName;
            }

            if (userEmail !== undefined) {
                localVarQueryParameter['user_email'] = userEmail;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users Count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersCountApiV1UserCountGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite User
         * @param {UserInviteDTO} userInviteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserApiV1UserInvitePost: async (userInviteDTO: UserInviteDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userInviteDTO' is not null or undefined
            assertParamExists('inviteUserApiV1UserInvitePost', 'userInviteDTO', userInviteDTO)
            const localVarPath = `/api/v1/user-invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInviteDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Password
         * @param {string} userName 
         * @param {string} tenantId 
         * @param {UserPasswordDTO} userPasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordApiV1UsersUserNamePasswordResetPatch: async (userName: string, tenantId: string, userPasswordDTO: UserPasswordDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('resetPasswordApiV1UsersUserNamePasswordResetPatch', 'userName', userName)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('resetPasswordApiV1UsersUserNamePasswordResetPatch', 'tenantId', tenantId)
            // verify required parameter 'userPasswordDTO' is not null or undefined
            assertParamExists('resetPasswordApiV1UsersUserNamePasswordResetPatch', 'userPasswordDTO', userPasswordDTO)
            const localVarPath = `/api/v1/users/{user_name}/password-reset`
                .replace(`{${"user_name"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantId !== undefined) {
                localVarQueryParameter['tenant_id'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Terminate User
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateUserApiV1UsersEmailDelete: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('terminateUserApiV1UsersEmailDelete', 'email', email)
            const localVarPath = `/api/v1/users/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {string} email 
         * @param {UserPatchDTO} userPatchDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserApiV1UsersEmailPatch: async (email: string, userPatchDTO: UserPatchDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('updateUserApiV1UsersEmailPatch', 'email', email)
            // verify required parameter 'userPatchDTO' is not null or undefined
            assertParamExists('updateUserApiV1UsersEmailPatch', 'userPatchDTO', userPatchDTO)
            const localVarPath = `/api/v1/users/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPatchDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersAPIApi - functional programming interface
 * @export
 */
export const UsersAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel User Invite
         * @param {UserInviteDTO} userInviteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelUserInviteApiV1UserInviteDelete(userInviteDTO: UserInviteDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelUserInviteApiV1UserInviteDelete(userInviteDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.cancelUserInviteApiV1UserInviteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create User
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserApiV1UsersPost(userDTO: UserDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPOSTReponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserApiV1UsersPost(userDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.createUserApiV1UsersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Users
         * @param {Status} [status] 
         * @param {string} [userName] 
         * @param {string} [userEmail] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersApiV1UsersGet(status?: Status, userName?: string, userEmail?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGETResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersApiV1UsersGet(status, userName, userEmail, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.getUsersApiV1UsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Users Count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersCountApiV1UserCountGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCountResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersCountApiV1UserCountGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.getUsersCountApiV1UserCountGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Invite User
         * @param {UserInviteDTO} userInviteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteUserApiV1UserInvitePost(userInviteDTO: UserInviteDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteUserApiV1UserInvitePost(userInviteDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.inviteUserApiV1UserInvitePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset Password
         * @param {string} userName 
         * @param {string} tenantId 
         * @param {UserPasswordDTO} userPasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordApiV1UsersUserNamePasswordResetPatch(userName: string, tenantId: string, userPasswordDTO: UserPasswordDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordApiV1UsersUserNamePasswordResetPatch(userName, tenantId, userPasswordDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.resetPasswordApiV1UsersUserNamePasswordResetPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Terminate User
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminateUserApiV1UsersEmailDelete(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminateUserApiV1UsersEmailDelete(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.terminateUserApiV1UsersEmailDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update User
         * @param {string} email 
         * @param {UserPatchDTO} userPatchDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserApiV1UsersEmailPatch(email: string, userPatchDTO: UserPatchDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPATCHResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserApiV1UsersEmailPatch(email, userPatchDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.updateUserApiV1UsersEmailPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersAPIApi - factory interface
 * @export
 */
export const UsersAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel User Invite
         * @param {UserInviteDTO} userInviteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUserInviteApiV1UserInviteDelete(userInviteDTO: UserInviteDTO, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.cancelUserInviteApiV1UserInviteDelete(userInviteDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create User
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApiV1UsersPost(userDTO: UserDTO, options?: RawAxiosRequestConfig): AxiosPromise<UserPOSTReponseDTO> {
            return localVarFp.createUserApiV1UsersPost(userDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Users
         * @param {Status} [status] 
         * @param {string} [userName] 
         * @param {string} [userEmail] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersApiV1UsersGet(status?: Status, userName?: string, userEmail?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<UserGETResponseDTO> {
            return localVarFp.getUsersApiV1UsersGet(status, userName, userEmail, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Users Count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersCountApiV1UserCountGet(options?: RawAxiosRequestConfig): AxiosPromise<UserCountResponseDTO> {
            return localVarFp.getUsersCountApiV1UserCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite User
         * @param {UserInviteDTO} userInviteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserApiV1UserInvitePost(userInviteDTO: UserInviteDTO, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.inviteUserApiV1UserInvitePost(userInviteDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Password
         * @param {string} userName 
         * @param {string} tenantId 
         * @param {UserPasswordDTO} userPasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordApiV1UsersUserNamePasswordResetPatch(userName: string, tenantId: string, userPasswordDTO: UserPasswordDTO, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.resetPasswordApiV1UsersUserNamePasswordResetPatch(userName, tenantId, userPasswordDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Terminate User
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateUserApiV1UsersEmailDelete(email: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.terminateUserApiV1UsersEmailDelete(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {string} email 
         * @param {UserPatchDTO} userPatchDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserApiV1UsersEmailPatch(email: string, userPatchDTO: UserPatchDTO, options?: RawAxiosRequestConfig): AxiosPromise<UserPATCHResponseDTO> {
            return localVarFp.updateUserApiV1UsersEmailPatch(email, userPatchDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersAPIApi - object-oriented interface
 * @export
 * @class UsersAPIApi
 * @extends {BaseAPI}
 */
export class UsersAPIApi extends BaseAPI {
    /**
     * 
     * @summary Cancel User Invite
     * @param {UserInviteDTO} userInviteDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public cancelUserInviteApiV1UserInviteDelete(userInviteDTO: UserInviteDTO, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).cancelUserInviteApiV1UserInviteDelete(userInviteDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create User
     * @param {UserDTO} userDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public createUserApiV1UsersPost(userDTO: UserDTO, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).createUserApiV1UsersPost(userDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Users
     * @param {Status} [status] 
     * @param {string} [userName] 
     * @param {string} [userEmail] 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public getUsersApiV1UsersGet(status?: Status, userName?: string, userEmail?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).getUsersApiV1UsersGet(status, userName, userEmail, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Users Count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public getUsersCountApiV1UserCountGet(options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).getUsersCountApiV1UserCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite User
     * @param {UserInviteDTO} userInviteDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public inviteUserApiV1UserInvitePost(userInviteDTO: UserInviteDTO, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).inviteUserApiV1UserInvitePost(userInviteDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Password
     * @param {string} userName 
     * @param {string} tenantId 
     * @param {UserPasswordDTO} userPasswordDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public resetPasswordApiV1UsersUserNamePasswordResetPatch(userName: string, tenantId: string, userPasswordDTO: UserPasswordDTO, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).resetPasswordApiV1UsersUserNamePasswordResetPatch(userName, tenantId, userPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Terminate User
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public terminateUserApiV1UsersEmailDelete(email: string, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).terminateUserApiV1UsersEmailDelete(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {string} email 
     * @param {UserPatchDTO} userPatchDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public updateUserApiV1UsersEmailPatch(email: string, userPatchDTO: UserPatchDTO, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).updateUserApiV1UsersEmailPatch(email, userPatchDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



