/* tslint:disable */
/* eslint-disable */
/**
 * Organizations API
 * <font color=\"green\"><b>Environment: DEV</b><hr/></font><br/>This onboards and manage organizations and organization administrators.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@logisticslabs.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface OrganizationDTO
 */
export interface OrganizationDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    'organization_name': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    'site_url_prefix': string;
    /**
     * 
     * @type {Status}
     * @memberof OrganizationDTO
     */
    'onboarding_status'?: Status;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    'admin_id': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    'admin_phone': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    'admin_email': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    'additional_details': string;
    /**
     * 
     * @type {Status}
     * @memberof OrganizationDTO
     */
    'admin_status'?: Status;
}


/**
 * 
 * @export
 * @interface OrganizationPATCHDTO
 */
export interface OrganizationPATCHDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationPATCHDTO
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPATCHDTO
     */
    'site_url_prefix'?: string;
    /**
     * 
     * @type {Status}
     * @memberof OrganizationPATCHDTO
     */
    'onboarding_status'?: Status;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPATCHDTO
     */
    'admin_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPATCHDTO
     */
    'admin_phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPATCHDTO
     */
    'admin_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPATCHDTO
     */
    'additional_details'?: string;
    /**
     * 
     * @type {Status}
     * @memberof OrganizationPATCHDTO
     */
    'admin_status'?: Status;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const Status = {
    Active: 'ACTIVE',
    Disabled: 'DISABLED',
    Invited: 'INVITED'
} as const;

export type Status = typeof Status[keyof typeof Status];


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * OrganizationsAPIApi - axios parameter creator
 * @export
 */
export const OrganizationsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Logo Organization
         * @param {string} tenantId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLogoOrganizationApiV1OrganizationsLogoTenantIdPut: async (tenantId: string, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('addLogoOrganizationApiV1OrganizationsLogoTenantIdPut', 'tenantId', tenantId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('addLogoOrganizationApiV1OrganizationsLogoTenantIdPut', 'file', file)
            const localVarPath = `/api/v1/organizations/logo/{tenant_id}`
                .replace(`{${"tenant_id"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Organizations
         * @param {OrganizationDTO} organizationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationsApiV1OrganizationsPost: async (organizationDTO: OrganizationDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationDTO' is not null or undefined
            assertParamExists('createOrganizationsApiV1OrganizationsPost', 'organizationDTO', organizationDTO)
            const localVarPath = `/api/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable Organization
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableOrganizationApiV1OrganizationsTenantIdDelete: async (tenantId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('disableOrganizationApiV1OrganizationsTenantIdDelete', 'tenantId', tenantId)
            const localVarPath = `/api/v1/organizations/{tenant_id}`
                .replace(`{${"tenant_id"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Organization Count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationCountApiV1OrganizationCountGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organization-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Organization Logo
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationLogoApiV1OrganizationLogoTenantIdGet: async (tenantId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getOrganizationLogoApiV1OrganizationLogoTenantIdGet', 'tenantId', tenantId)
            const localVarPath = `/api/v1/organization/logo/{tenant_id}`
                .replace(`{${"tenant_id"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Organization Sites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationSitesApiV1OrganizationSitesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organization-sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Organizations
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsApiV1OrganizationsGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (tenantId !== undefined) {
                localVarQueryParameter['tenant_id'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Organizations
         * @param {string} tenantId 
         * @param {OrganizationPATCHDTO} [organizationPATCHDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationsApiV1OrganizationsTenantIdPatch: async (tenantId: string, organizationPATCHDTO?: OrganizationPATCHDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateOrganizationsApiV1OrganizationsTenantIdPatch', 'tenantId', tenantId)
            const localVarPath = `/api/v1/organizations/{tenant_id}`
                .replace(`{${"tenant_id"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationPATCHDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsAPIApi - functional programming interface
 * @export
 */
export const OrganizationsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Logo Organization
         * @param {string} tenantId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLogoOrganizationApiV1OrganizationsLogoTenantIdPut(tenantId: string, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLogoOrganizationApiV1OrganizationsLogoTenantIdPut(tenantId, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsAPIApi.addLogoOrganizationApiV1OrganizationsLogoTenantIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Organizations
         * @param {OrganizationDTO} organizationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganizationsApiV1OrganizationsPost(organizationDTO: OrganizationDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganizationsApiV1OrganizationsPost(organizationDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsAPIApi.createOrganizationsApiV1OrganizationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Disable Organization
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableOrganizationApiV1OrganizationsTenantIdDelete(tenantId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableOrganizationApiV1OrganizationsTenantIdDelete(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsAPIApi.disableOrganizationApiV1OrganizationsTenantIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Organization Count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationCountApiV1OrganizationCountGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationCountApiV1OrganizationCountGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsAPIApi.getOrganizationCountApiV1OrganizationCountGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Organization Logo
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationLogoApiV1OrganizationLogoTenantIdGet(tenantId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationLogoApiV1OrganizationLogoTenantIdGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsAPIApi.getOrganizationLogoApiV1OrganizationLogoTenantIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Organization Sites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationSitesApiV1OrganizationSitesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationSitesApiV1OrganizationSitesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsAPIApi.getOrganizationSitesApiV1OrganizationSitesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Organizations
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationsApiV1OrganizationsGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationsApiV1OrganizationsGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsAPIApi.getOrganizationsApiV1OrganizationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Organizations
         * @param {string} tenantId 
         * @param {OrganizationPATCHDTO} [organizationPATCHDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationsApiV1OrganizationsTenantIdPatch(tenantId: string, organizationPATCHDTO?: OrganizationPATCHDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationsApiV1OrganizationsTenantIdPatch(tenantId, organizationPATCHDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsAPIApi.updateOrganizationsApiV1OrganizationsTenantIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganizationsAPIApi - factory interface
 * @export
 */
export const OrganizationsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Logo Organization
         * @param {string} tenantId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLogoOrganizationApiV1OrganizationsLogoTenantIdPut(tenantId: string, file: File, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.addLogoOrganizationApiV1OrganizationsLogoTenantIdPut(tenantId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Organizations
         * @param {OrganizationDTO} organizationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationsApiV1OrganizationsPost(organizationDTO: OrganizationDTO, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createOrganizationsApiV1OrganizationsPost(organizationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable Organization
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableOrganizationApiV1OrganizationsTenantIdDelete(tenantId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.disableOrganizationApiV1OrganizationsTenantIdDelete(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Organization Count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationCountApiV1OrganizationCountGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getOrganizationCountApiV1OrganizationCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Organization Logo
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationLogoApiV1OrganizationLogoTenantIdGet(tenantId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getOrganizationLogoApiV1OrganizationLogoTenantIdGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Organization Sites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationSitesApiV1OrganizationSitesGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getOrganizationSitesApiV1OrganizationSitesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Organizations
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsApiV1OrganizationsGet(tenantId?: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getOrganizationsApiV1OrganizationsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Organizations
         * @param {string} tenantId 
         * @param {OrganizationPATCHDTO} [organizationPATCHDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationsApiV1OrganizationsTenantIdPatch(tenantId: string, organizationPATCHDTO?: OrganizationPATCHDTO, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updateOrganizationsApiV1OrganizationsTenantIdPatch(tenantId, organizationPATCHDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsAPIApi - object-oriented interface
 * @export
 * @class OrganizationsAPIApi
 * @extends {BaseAPI}
 */
export class OrganizationsAPIApi extends BaseAPI {
    /**
     * 
     * @summary Add Logo Organization
     * @param {string} tenantId 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsAPIApi
     */
    public addLogoOrganizationApiV1OrganizationsLogoTenantIdPut(tenantId: string, file: File, options?: RawAxiosRequestConfig) {
        return OrganizationsAPIApiFp(this.configuration).addLogoOrganizationApiV1OrganizationsLogoTenantIdPut(tenantId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Organizations
     * @param {OrganizationDTO} organizationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsAPIApi
     */
    public createOrganizationsApiV1OrganizationsPost(organizationDTO: OrganizationDTO, options?: RawAxiosRequestConfig) {
        return OrganizationsAPIApiFp(this.configuration).createOrganizationsApiV1OrganizationsPost(organizationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable Organization
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsAPIApi
     */
    public disableOrganizationApiV1OrganizationsTenantIdDelete(tenantId: string, options?: RawAxiosRequestConfig) {
        return OrganizationsAPIApiFp(this.configuration).disableOrganizationApiV1OrganizationsTenantIdDelete(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Organization Count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsAPIApi
     */
    public getOrganizationCountApiV1OrganizationCountGet(options?: RawAxiosRequestConfig) {
        return OrganizationsAPIApiFp(this.configuration).getOrganizationCountApiV1OrganizationCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Organization Logo
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsAPIApi
     */
    public getOrganizationLogoApiV1OrganizationLogoTenantIdGet(tenantId: string, options?: RawAxiosRequestConfig) {
        return OrganizationsAPIApiFp(this.configuration).getOrganizationLogoApiV1OrganizationLogoTenantIdGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Organization Sites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsAPIApi
     */
    public getOrganizationSitesApiV1OrganizationSitesGet(options?: RawAxiosRequestConfig) {
        return OrganizationsAPIApiFp(this.configuration).getOrganizationSitesApiV1OrganizationSitesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Organizations
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsAPIApi
     */
    public getOrganizationsApiV1OrganizationsGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return OrganizationsAPIApiFp(this.configuration).getOrganizationsApiV1OrganizationsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Organizations
     * @param {string} tenantId 
     * @param {OrganizationPATCHDTO} [organizationPATCHDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsAPIApi
     */
    public updateOrganizationsApiV1OrganizationsTenantIdPatch(tenantId: string, organizationPATCHDTO?: OrganizationPATCHDTO, options?: RawAxiosRequestConfig) {
        return OrganizationsAPIApiFp(this.configuration).updateOrganizationsApiV1OrganizationsTenantIdPatch(tenantId, organizationPATCHDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



