import { Configuration } from "../../swagger-apis/generated-users-api/configuration";

export abstract class GenericApiClientBase {
    protected abstract getAccessToken(): string | null;
    abstract base_url: string;
  
    protected configuration() {
      const openapiConfig = new Configuration();
      openapiConfig.baseOptions = {
        headers: { Authorization: 'Bearer ' + this.getAccessToken() },
      };
      openapiConfig.basePath = this.base_url;
      return openapiConfig;
    }  
  }
  