import { store } from "../../redux/store";
import { OrganizationsAPIApi } from "../../swagger-apis/generated-organization-api";
import { GenericApiClientBase } from "../common/GenericApiClientBase";

export class OrganizationApiClient extends GenericApiClientBase {
  base_url: string = process.env.REACT_APP_ORGANIZATION_API_URL || '';

  protected getAccessToken(): string | null {
    const state = store.getState();
    const token = state?.auth?.organization_token;
    return token;
  }

  public organizationApiService = () => {
    const api = new OrganizationsAPIApi(this.configuration());
    return api;
  }

}
