import bell from "../../assets/images/bell.svg";
import img from "../../assets/images/logistics2.png";
import settings from "../../assets/images/settings.svg";
import Logo from "./Logo";
import UserOptionsDropdown from "./UserOptionsDropdown";

interface HeaderProps {
  logoutRedirectionPath: string;
}

const Header: React.FC<HeaderProps> = ({ logoutRedirectionPath }) => {
  return (
    <header className="bg-[#0D1423] text-white flex justify-between items-center p-4 w-full relative min-h-[56px]">
      {/* Mobile logo centered */}
      <div className="absolute left-1/2 transform -translate-x-1/2 lg:relative lg:left-auto lg:transform-none cursor-pointer">
        <Logo
          className="h-6 md:h-8"
          imagePath={img}
        />
      </div>

      {/* Icons visible on larger screens */}
      <div className="hidden lg:flex items-center space-x-10 mr-6">
        <img
          src={bell}
          alt="Bell Icon"
          className="h-6 w-6 text-[#5D5E68]"
        />
        <img
          src={settings}
          alt="Settings Icon"
          className="h-6 w-6"
        />

        {/* Account and Modal Container */}
        <UserOptionsDropdown logoutRedirectionPath={logoutRedirectionPath} />
      </div>
    </header>
  );
};

export default Header;
