import { setOrganizationToken } from "../../redux/authSlice";
import { AppDispatch } from "../../redux/store";
import { OAuthTokenClientBase } from "../common/OAuthTokenClientBase";

export class OrganizationTokenClient extends OAuthTokenClientBase {
    base_url: string = process.env.REACT_APP_ORGANIZATION_API_URL || '';
    client_id: string = process.env.REACT_APP_ORGANIZATION_CLIENT_ID || '';;
    client_secret: string = process.env.REACT_APP_ORGANIZATION_CLIENT_SECRET || '';
    scope = process.env.REACT_APP_ORGANIZATION_SCOPE || '';

    async GetOAuth2Token(dispatch: AppDispatch, username: string, password: string, scope: string): Promise<void> {

        await super.GetOAuth2Token(dispatch, username, password, scope);
        dispatch(setOrganizationToken(this.token));
    }

}